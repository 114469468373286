import { PUNCH_LIST_DEFAULT_FORM } from 'domain/punch-list';
import moment from 'moment';

export const getPunchListParams = (filters) => {
  let url = `?page=${filters.page || 1}`;
  console.log('filters are', filters);
  if (filters.search && filters.search.length > 0)
    url += `&search=${filters.search}`;
  if (filters.titleOrder && filters.titleOrder !== 'all')
    url += `&title_order=${filters.titleOrder}`;
  if (filters.status) url += `&${filters.status}=true`;
  if (filters.due_date) url += `&due_date=${filters.due_date}`;
  if (filters.priority) url += `&${filters.priority}=true`;
  if (filters.item_type) url += `&by_type=${filters.item_type}`;
  if (filters.tags && filters.tags.length > 0)
    url += `&by_tags=${filters.tags.map((t) => t.value).join(',')}`;
  if (filters.assignee) url += `&assignee=${filters.assignee.value}`;
  if (filters.manager) url += `&manager=${filters.manager.value}`;
  if (filters.trades && filters.trades.length > 0)
    url += `&by_trades=${filters.trades.map((t) => t.value).join(',')}`;
  if (filters.dueFrom) url += `&by_due__date_from=${filters.dueFrom}`;
  if (filters.dueTo) url += `&by_due__date_to=${filters.dueTo}`;
  if (filters.approver) url += `&approver=${filters.approver.value}`;

  return url;
};

export const punchListDataToForm = (data = {}) => {
  console.log('TODO: serialize data from back to form');

  return PUNCH_LIST_DEFAULT_FORM;
};

export const mapFormToApiPayload = (form) => {
  let processedStatus,
    processedItemType,
    processedManager,
    processedApprover,
    processedPriority;
  let processedCsiCode = form.csi_code;
  if (typeof form.status === 'object') {
    processedStatus = form.status.value;
  } else {
    processedStatus = form.status;
  }
  if (typeof form.item_type === 'object') {
    processedItemType = form.item_type.value;
  } else {
    processedItemType = form.item_type;
  }
  if (typeof form.item_type === 'object') {
    processedItemType = form.item_type.value;
  } else {
    processedItemType = form.item_type;
  }
  if (typeof form.priority === 'object') {
    processedPriority = form.priority.value;
  } else {
    processedPriority = form.priority;
  }

  if (form.approver) {
    processedApprover = form.approver.id
      ? { ...form.approver, value: form.approver.id }
      : form.approver;
  } else {
    processedApprover = null;
  }

  if (form.manager) {
    processedManager = form.manager.id
      ? { ...form.manager, value: form.manager.id }
      : form.manager;
  } else {
    processedManager = null;
  }

  if (form.csi_code) {
    processedCsiCode = form.csi_code.id
      ? { ...form.csi_code, value: form.csi_code.id }
      : form.csi_code;
  } else {
    processedCsiCode = null;
  }

  try {
    const payload = {
      title: form.title,
      item_type: form.item_type ? processedItemType : null,
      csi_code_id: form.csi_code ? processedCsiCode.value : null,
      priority: form.priority ? processedPriority : null,
      due_date: form.due_date
        ? moment(form.due_date).format('YYYY-MM-DD')
        : null,
      project_site_id: form.project_site ? form.project_site.value : null,
      manager_id: form.manager ? processedManager.value : null,
      approver_id: form.approver ? processedApprover.value : null,
      description: form.description || '',
      assignee_ids: Array.isArray(form.assignees)
        ? form.assignees.map((assignee) => assignee[0])
        : [],
      status: form.status ? processedStatus : null,
      documents: form.documents || [],
      photos: form.photos || [],
      deleted_photos: form.deleted_photos || [],
      deleted_documents: form.deleted_documents || [],
    };

    return payload;
  } catch (error) {
    console.error('Error in mapFormToApiPayload:', error);
  }
};

class PunchList {
  isDraft = ({ status }) => status === 'draft';

  isOpen = ({ status }) => status === 'open';

  isInProgress = ({ status }) => status === 'in-progress';

  isOnReview = ({ status }) => status === 'on-review';

  isComplete = ({ status }) => status === 'complete';

  isClosed = ({ status }) => status === 'closed';
}

export default new PunchList();
