import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import FilterHeader from 'components/shared/table/FilterHeader';
import SortHeader from 'components/shared/table/SortHeader';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import {
  itemDueOptions,
  itemPriorityOptions,
  itemStatusOptions,
  itemTypeOptions,
} from 'domain/punch-list';
import PunchListPermissions from 'permissions/punch-list-permissions';

import { setPunchListForm, setPunchListPdfViewer } from './store/actions';

import {
  getPunchListFilters,
  getPunchListItems,
  getPunchListTotalItems,
} from './store/selectors';

import './PunchListTable.css';
import PunchListEmailModal from './PunchListEmailModal';
import { capitalizeFirstLetters } from '../../../../../services/utils/case-utils';

const PunchListTable = (props) => {
  const {
    items,
    total,
    currentUser,
    currentFilters,
    currentProject,
    handleFilterChange: hfc,
    dispatch,
    onEditItem,
  } = props;
  const [pdfUrl, setPdfUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  console.log('currentFilters', currentFilters);
  const tableHeaders = [
    'NO',
    <SortHeader
      label="TITLE"
      selected={(currentFilters.titleOrder || 'all') !== 'all'}
      desc={currentFilters.titleOrder === 'desc'}
      onChange={(selected, desc) =>
        hfc({ titleOrder: !selected ? null : desc ? 'desc' : 'asc' })
      }
    />,
    'TRADE',
    <FilterHeader
      label="TYPE"
      options={itemTypeOptions.map(({ value: id, label }) => ({ id, label }))}
      selected={currentFilters.item_type && currentFilters.item_type.value}
      onFilter={(item_type) => hfc({ item_type })}
      firstOption
      clearable
    />,
    'LOCATION',
    'MANAGER',
    'ASSIGNEE',
    'APPROVER',
    <FilterHeader
      label="PRIORITY"
      options={itemPriorityOptions.map(({ value: id, label }) => ({
        id,
        label,
      }))}
      selected={currentFilters.priority && currentFilters.priority.value}
      onFilter={(priority) => hfc({ priority })}
      firstOption
      clearable
    />,
    <FilterHeader
      label="DUE"
      options={itemDueOptions}
      selected={currentFilters.due && currentFilters.due.value}
      onFilter={(due) => hfc({ due })}
      firstOption
      clearable
    />,
    <FilterHeader
      label="STATUS"
      options={itemStatusOptions.map(({ value: id, label }) => ({ id, label }))}
      selected={currentFilters.status && currentFilters.status.value}
      onFilter={(status) => {
        console.log('HFC', status);
        hfc({ status });
      }}
      firstOption
      clearable
    />,
    // 'TAGS',
    '',
  ];

  const handleView = (item) => {
    handleEdit(item);
  };

  const handleViewPdf = (item) => {
    dispatch(setPunchListForm(item));
    dispatch(setPunchListPdfViewer(true));
    setPdfUrl(item.pdf_document.document.url);
    setShowPdf(true);
  };

  const handleClosePdf = () => {
    dispatch(setPunchListForm(null));
    dispatch(setPunchListPdfViewer(false));
    setShowPdf(false);
    setPdfUrl('');
  };

  const handleEdit = (item) => {
    const hasUrl = (i) => i && i.image;
    const hasDocUrl = (d) => d && d.document;
    const modifiedImages =
      item &&
      item.attached_images &&
      item.attached_images.map((i) => {
        return { ...i, url: hasUrl(i) ? i.image.url : null };
      });
    const modifiedDocuments =
      item &&
      item.attached_documents &&
      item.attached_documents.map((d) => {
        return {
          ...d,
          url: hasDocUrl(d) ? d.document.url : null,
          name: 'Test',
        };
      });

    const modifiedItem = {
      ...item,
      photos: modifiedImages,
      documents: modifiedDocuments,
    };
    if (onEditItem) onEditItem(modifiedItem);
  };

  const handleSendEmail = (item) => {
    dispatch(setPunchListForm(item));
    dispatch(setShowEmailModal(true));
  };

  const onRowSelected = (index) => {
    const item = items[index];
    // handleView(item);
    handleEdit(item);
  };

  const renderRowActions = (item) => {
    const actions = [];
    if (PunchListPermissions.canEmail(currentUser, currentProject, item))
      actions.push({ content: 'Email', onClick: () => handleSendEmail(item) });

    if (PunchListPermissions.canEdit(currentUser, currentProject, item))
      actions.push({ content: 'Edit', onClick: () => handleEdit(item) });
    if (!PunchListPermissions.canEdit(currentUser, currentProject, item))
      actions.push({ Content: 'View', onClick: () => handleView(item) });

    if (
      item &&
      item.pdf_document &&
      item.pdf_document.document &&
      item.pdf_document.document.url
    )
      actions.push({ content: 'View PDF', onClick: () => handleViewPdf(item) });

    return (
      <div className="actions">
        <Menu
          key="more-actions"
          selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
          items={actions}
        />
      </div>
    );
  };

  function formatDate(date) {
    return moment(date).format('MM/DD/YYYY');
  }

  const rows = items.map((item) => {
    const no = item.formatted_number;
    const title = capitalizeFirstLetters(item.title) || '';
    const csi_code = item.csi_code ? item.csi_code.contributor_type : '';

    const item_type =
      item.item_type
        .split('_')
        .map((word) => capitalizeFirstLetters(word))
        .join(' ') || '';
    const project_site = item.project_site
      ? `${item.project_site.name} (#${item.project_site.number})`
      : 'N/A';
    const manager = item.manager
      ? item.manager.full_name
        ? capitalizeFirstLetters(item.manager.full_name)
        : item.manager.first_name && item.manager.last_name
        ? `${capitalizeFirstLetters(
            item.manager.first_name
          )} ${capitalizeFirstLetters(item.manager.last_name)}`
        : item.manager.email || ''
      : '';
    const assignee =
      item.assignees && item.assignees.length > 0
        ? item.assignees
            .map(
              (assignee) =>
                assignee.full_name ||
                `${assignee.first_name} ${assignee.last_name}` ||
                assignee.email
            )
            .join(', ')
        : '';
    const approver = item.approver
      ? item.approver.full_name
        ? capitalizeFirstLetters(item.approver.full_name)
        : item.approver.first_name && item.approver.last_name
        ? `${capitalizeFirstLetters(
            item.approver.first_name
          )} ${capitalizeFirstLetters(item.approver.last_name)}`
        : item.approver.email || ''
      : '';
    const priority = item.priority.toUpperCase() || '';
    const due_date = item.due_date ? formatDate(item.due_date) : '';
    const status =
      (item.status &&
        item.status
          .split('_')
          .map((word) => capitalizeFirstLetters(word))
          .join(' ')) ||
      '';

    // const tags = item.tags && item.tags.length > 0 ? item.tags.join(', ') : '';
    const actions = renderRowActions(item);

    return [
      no,
      title,
      csi_code,
      item_type,
      project_site,
      manager,
      assignee,
      approver,
      priority,
      due_date,
      status,
      // tags,
      actions,
    ];
  });

  return (
    <div className={`punch-table ${rows.length === 0 ? 'empty-table' : ''}`}>
      <div className="punch-list-table-container">
        <SimpleTable
          className={'punch-list-table'}
          emptyMessage={'No Items'}
          headers={tableHeaders}
          onRowSelected={onRowSelected}
          actionCells={1}
          rows={rows}
        />
      </div>
      <div className="pagination-container">
        <SimplePagination
          onPageChange={(page) => hfc('page', page)}
          pageSize={currentFilters.pageSize}
          currentPage={currentFilters.page}
          total={total}
        />
      </div>
      <PunchListEmailModal />
      <PdfViewer
        content={{
          source: pdfUrl,
          type: 'url',
        }}
        show={showPdf}
        onClose={handleClosePdf}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  currentProject: getCurrentProject(state),
  currentFilters: getPunchListFilters(state),
  total: getPunchListTotalItems(state),
  items: getPunchListItems(state),
});

export default withRouter(connect(mapStateToProps)(PunchListTable));
