import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './ProjectDetails.css';
import numeral from 'numeral';
import Badge from '@mui/material/Badge';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import SearchBar from 'components/shared/navigation/SearchBar';
import {
  initProjectDetails,
  loadProjectRequest,
  selectNavigationBar,
  setShowPdfViewer,
  changeProjectStatus,
  setShowDashboard,
} from 'components/admin/projects/details/store/actions';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import {
  getCurrentProject,
  getNavBarSelectedItem,
  getShowPdfViewer,
  getShowDashboard,
} from 'components/admin/projects/details/store/selectors';
import {
  getProjectOpenItemsCount,
  getProjectOpenItemsCritCount,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import { getCloseoutPercentage } from 'components/admin/projects/details/project-closeout/store/selectors';
import { getSubDailyReportsCount } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { openMeeting } from 'components/admin/projects/details/project-meeting-minutes/store/actions';
import { openRfi } from 'components/admin/projects/details/project-rfi-log/store/actions';
import { openSubmittal } from 'components/admin/projects/details/project-submittal-log/store/actions';
import {
  openDraftReport,
  setDailyReportTab,
  loadFieldWorkOrdersRequest,
  openFieldWorkOrder,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import {
  showOpenItemsModal,
  loadOpenItem,
  openItemsCount,
  initOpenItemsCount,
} from 'components/admin/projects/details/project-open-items/store/actions';
import { closeoutPercentage } from 'components/admin/projects/details/project-closeout/store/actions';
import {
  subDailyReportsCount,
  initSubDailyReportsCount,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
// import ProjectProposal from 'components/admin/projects/details/project-proposal/ProjectProposal';
import ProjectDirectory from 'components/admin/projects/details/project-directory/ProjectDirectory';
import ProjectMeetingMinutes from 'components/admin/projects/details/project-meeting-minutes/ProjectMeetingMinutes';
import ProjectRfiLog from 'components/admin/projects/details/project-rfi-log/ProjectRfiLog';
import ProjectSubmittalLog from 'components/admin/projects/details/project-submittal-log/ProjectSubmittalLog';
import ProjectDailyReportLog from 'components/admin/projects/details/project-daily-report-log/ProjectDailyReportLog';
import ProjectDocumentation from 'components/admin/projects/details/project-documentation/ProjectDocumentation';
import ProjectOpenItemsModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemsModal';
import ProjectOpenItemsReportModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemsReportModal';
import ProjectCloseout from 'components/admin/projects/details/project-closeout/ProjectCloseout';
import ProjectSecurityCameras from 'components/admin/projects/details/project-security-camera/ProjectSecurityCameras';
import CoiExps from 'components/admin/projects/details/project-coi-exps/CoiExps';
import ProjectHome from 'components/admin/projects/details/project-home/ProjectHome';
import ProjectDashboard from 'components/admin/projects/details/project-dashboard/ProjectDashboard';
import ProjectRfaLog from 'components/admin/projects/details/project-rfa-log/ProjectRfaLog.js';
import { ProjectStatusEnum } from 'domain/project-status-enum';
import { getCurrentUser } from 'selectors/authentication';
import { goToEditProject } from 'components/admin/projects/store/actions';
import queryString from 'query-string';
import CounterButton from 'components/shared/button/CounterButton';
import ProjectStatus from 'components/shared/project-status/ProjectStatus';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  setShowLostModal,
  setShowCancelModal,
} from 'components/admin/projects/details/project-proposal/store/actions';
import {
  getShowLostOrCancelledModal,
  getIsCancelledModal,
} from 'components/admin/projects/details/project-proposal/store/selectors';
import ProjectCancelledOrLostModal from 'components/admin/projects/details/project-proposal/ProjectCancelledOrLostModal';
import Date from 'components/shared/text/Date';
import User from 'domain/user';
import ProjectPermissions from 'permissions/project-permissions';
import ProjectDashboardPermissions from 'permissions/project-dashboard-permissions';
import DocumentPermissions from 'permissions/document-permissions';
import SecurityCameraPermissions from 'permissions/security-camera-permissions';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
/* import DropdownMenu, {
  DROPDOWN_TYPES,
} from 'components/shared/menu/DropdownMenu'; */
import MegaMenuDropdown, {
  DROPDOWN_TYPES,
} from 'components/shared/mega-menu/MegaMenuDropdown';
import ProjectPhotos from 'components/admin/projects/details/project-photos/ProjectPhotos';
import { setProjectReportOpenDetails } from 'components/admin/projects/details/project-daily-report-log/store/actions';
import Switch from 'components/shared/switch/Switch';
import Footer from 'components/shared/footer/Footer';
import { openRfa } from 'components/admin/projects/details/project-rfa-log/store/actions';
import { setInitialPrefixDocuments } from 'components/admin/projects/details/project-documentation/store/actions.js';
import ProjectSchedule from 'components/admin/projects/details/project-schedule/ProjectSchedule';
import { loadPdfPublishScheduleCategory } from 'components/admin/projects/details/project-schedule/store/actions';
import { withRouter } from 'react-router-dom';
import ProjectPunchList from 'components/admin/projects/details/project-punch-list/ProjectPunchList';

import {
  getMeetingsInstance,
  getMeetingsParent,
  setInstanceFormShow,
} from './project-new-meetings-module/store/actions';
import { getPersistedShowDashboard } from './store/persist';
import ProjectNewMeetings from './project-new-meetings-module/ProjectNewMeetings';

class ProjectDetails extends Component {
  static DETAIL_SECTIONS = {
    HOME: 'home',
    DIRECTORY: 'directory',
    SOV: 'sov',
    DOCUMENTS: 'documents',
    PHOTOS: 'photos',
    COIS: 'cois',
    MEETINGS: 'meetings',
    NEW_MEETINGS: 'new_meetings',
    RFAS: 'rfas',
    RFIS: 'rfis',
    SUBMITTALS: 'submittals',
    SCHEDULE: 'schedule',
    DAILY_REPORTS: 'reporting',
    CLOSEOUT: 'closeout',
    SECURITY_CAMERAS: 'security_cameras',
    PUNCH_LIST: 'punch_list',
  };

  constructor(props) {
    super(props);

    props.dispatch(initProjectDetails());
    props.dispatch(initOpenItemsCount());
    props.dispatch(initSubDailyReportsCount());
  }

  componentDidMount() {
    this.loadProjectDetails(this.props).then(() => {
      this.updateSectionFromURL();
    });
    this.displayEmailPdf();
  }

  componentDidUpdate(prevProps) {
    const { match, location } = this.props;
    if (
      match.params.projectId !== prevProps.match.params.projectId ||
      match.params.section !== prevProps.match.params.section ||
      location.search !== prevProps.location.search
    ) {
      if (
        match.params.projectId !== prevProps.match.params.projectId ||
        match.params.section !== prevProps.match.params.section
      ) {
        this.loadProjectDetails(this.props).then(() => {
          this.updateSectionFromURL();
        });
      } else if (location.search !== prevProps.location.search) {
        this.updateSectionFromURL();
      }
    }
  }

  updateSectionFromURL = () => {
    const { match, dispatch, location } = this.props;
    const section = match.params.section || ProjectDetails.DETAIL_SECTIONS.HOME;

    dispatch(selectNavigationBar(section));

    const state =
      location && (location.state || queryString.parse(location.search));

    const { currentUser } = this.props;
    const projectId = this.projectId;

    if (state.meetingId || state.meetings) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.MEETINGS));
      if (state.meetingId) {
        dispatch(openMeeting(projectId, state.meetingId));
      }
    } else if (state.meetingsInstanceId) {
      dispatch(
        selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.NEW_MEETINGS)
      );
      dispatch(getMeetingsInstance(projectId, state.meetingsInstanceId));
      dispatch(setInstanceFormShow(true));
    } else if (state.meetingsParentId) {
      dispatch(
        selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.NEW_MEETINGS)
      );
      dispatch(getMeetingsParent(projectId, state.meetingsParentId));
    } else if (state.rfiId) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.RFIS));
      dispatch(openRfi(projectId, state.rfiId));
    } else if (state.submittalId || state.submittals) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.SUBMITTALS));
      if (state.submittalId) {
        dispatch(openSubmittal(projectId, state.submittalId, currentUser));
      }
    } else if (state.dailyReportId) {
      dispatch(
        selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS)
      );
      dispatch(
        openDraftReport(
          projectId,
          state.dailyReportId,
          state.subDailyReport === 'true',
          true
        )
      );
    } else if (state.fieldWorkOrderId) {
      dispatch(
        selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS)
      );
      dispatch(setDailyReportTab(3));
      dispatch(loadFieldWorkOrdersRequest(projectId, 1));
      dispatch(openFieldWorkOrder(projectId, state.fieldWorkOrderId));
    } else if (state.directory) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.DIRECTORY));
    } else if (state.projectOpenItemId) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.DIRECTORY));
      state.projectOpenItemCritical === 'true'
        ? this.onCriticalOpenItemsClick()
        : this.onOpenItemsClick();
      dispatch(loadOpenItem(projectId, state.projectOpenItemId));
    } else if (state.documentation) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.DOCUMENTS));
      if (state.folder) {
        dispatch(setInitialPrefixDocuments(state.folder));
      }
    } else if (state.closeout) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.CLOSEOUT));
    } else if (state.albumId || state.photos) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.PHOTOS));
    } else if (state.projectReportId || state.projectReports) {
      dispatch(
        selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS)
      );
      if (state.projectReportId) {
        dispatch(
          setProjectReportOpenDetails({
            value: true,
            id: state.projectReportId,
          })
        );
      }
    } else if (state.fieldReports) {
      dispatch(
        selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS)
      );
    } else if (state.rfaId || state.rfas) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.RFAS));
      if (state.rfaId) {
        dispatch(openRfa(projectId, state.rfaId, true));
      }
    } else if (state.schedule) {
      dispatch(selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.SCHEDULE));
      if (state.categoryId) {
        dispatch(
          loadPdfPublishScheduleCategory(
            projectId,
            { id: state.categoryId, pdf_document: null },
            false
          )
        );
      }
    } else if (state.securityCameras) {
      dispatch(
        selectNavigationBar(ProjectDetails.DETAIL_SECTIONS.SECURITY_CAMERAS)
      );
    }
  };

  loadProjectDetails = (props) => {
    this.projectId = numeral(props.match.params.projectId).value();

    return props
      .dispatch(loadProjectRequest(this.projectId))
      .then(({ project }) => {
        const { currentUser } = props;

        if (User.isContractor(currentUser, project)) {
          props.dispatch(subDailyReportsCount(this.projectId));
        }

        this.initializeShowDashboard(project);

        props.dispatch(openItemsCount(this.projectId));
        props.dispatch(closeoutPercentage(this.projectId));
      });
  };

  handleNavigationItemSelect = (value) => {
    const { history, match } = this.props;
    const projectId = match.params.projectId;
    const newPath = `/admin/projects/${projectId}/${value || 'home'}`;
    const currentPath = history.location.pathname;

    if (currentPath !== newPath) {
      history.push(newPath);
    }

    simpleScrollToTop();
    this.props.dispatch(
      selectNavigationBar(value || ProjectDetails.DETAIL_SECTIONS.HOME)
    );
  };

  projectApproved = () => {
    const projStatus = ProjectStatusEnum.create(
      this.props.currentProject.status
    );
    return !ProjectStatusEnum.PENDING.equals(projStatus);
  };

  onCriticalOpenItemsClick = (e) => {
    const { currentProject, dispatch } = this.props;
    dispatch(showOpenItemsModal(currentProject.id, 1, true));
    e && e.stopPropagation();
  };

  onOpenItemsClick = (e) => {
    const { currentProject, dispatch } = this.props;
    dispatch(showOpenItemsModal(currentProject.id, 1, false));
    e && e.stopPropagation();
  };

  getStatusModalFn(status) {
    switch (status) {
      case ProjectStatusEnum.LOST:
        return () => this.props.dispatch(setShowLostModal(true));
      case ProjectStatusEnum.CANCELLED:
        return () => this.props.dispatch(setShowCancelModal(true));
      default:
        return null;
    }
  }

  changeStatus = (status) => {
    const { currentProject } = this.props;
    const modalFn = this.getStatusModalFn(status);
    if (modalFn) {
      modalFn();
    } else {
      this.props.dispatch(
        changeProjectStatus(currentProject.id, status.status)
      );
    }
  };

  onEditClick = () => {
    this.props.dispatch(goToEditProject(this.props.currentProject.id));
  };

  tooltip = (currentProject) => {
    const tooltipData = (
      <Tooltip placement="right" className="in" id="tooltip-right">
        {currentProject.termination_category}{' '}
        {currentProject.termination_reason}
      </Tooltip>
    );
    return tooltipData;
  };

  displayEmailPdf = () => {
    let params = new URL(document.location).searchParams;
    if (params.get('show_pdf_view') === 'true') {
      this.setState({ pdf_url: params.get('pdf_url') });
      this.props.dispatch(setShowPdfViewer(true));
    }
  };

  handlePdfViewerClose = () => {
    this.props.dispatch(setShowPdfViewer(false));
  };

  initializeShowDashboard = (project) => {
    const { currentUser } = this.props;
    let show = getPersistedShowDashboard(currentUser.id);
    if (!ProjectDashboardPermissions.canView(currentUser, project))
      show = false;
    else if (
      show == null &&
      ProjectDashboardPermissions.canViewByDefault(currentUser, project)
    )
      show = true;

    this.props.dispatch(setShowDashboard(show, currentUser.id));
  };

  handleShowDashboard = (show) =>
    this.props.dispatch(setShowDashboard(show, this.props.currentUser.id));

  renderStatus() {
    const { currentUser, currentProject } = this.props;
    const status = ProjectStatusEnum.create(currentProject.status);
    const modalFn =
      ProjectPermissions.canEditStatusDetails(currentUser, currentProject) &&
      this.getStatusModalFn(status);
    let component = (
      <div
        className={`detail-status-container${modalFn ? ' status-action' : ''}`}
        onClick={
          modalFn
            ? (e) => {
                e.stopPropagation();
                modalFn();
              }
            : undefined
        }
      >
        <span className="status-title">Project Stage:</span>
        <ProjectStatus status={status} type="label" />
        {currentProject.bid_due_date && !this.projectApproved() && (
          <span className="bid-due-date">
            {' '}
            BID Due:{' '}
            <Date
              className="bid-due-date-date"
              value={currentProject.bid_due_date}
              format="MM/DD/YYYY"
            />
          </span>
        )}
      </div>
    );

    if (modalFn && currentProject.termination_reason) {
      component = (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip placement="right" className="in" id="tooltip-right">
              {currentProject.termination_category}{' '}
              {currentProject.termination_reason}
            </Tooltip>
          }
        >
          {component}
        </OverlayTrigger>
      );
    }

    return component;
  }

  renderHeaderButtons() {
    const { projectOpenItemsCount, projectOpenItemsCritCount } = this.props;

    return [
      <CounterButton
        key="items-button"
        className="items-button button-blue-grey"
        label="Open Items"
        counter={projectOpenItemsCount}
        onClick={this.onOpenItemsClick}
      />,
      <CounterButton
        key="critical-button"
        className="critical-button button-green"
        label="Critical Items"
        counter={projectOpenItemsCritCount}
        onClick={this.onCriticalOpenItemsClick}
      />,
    ].filter((component) => component);
  }

  renderHeaderGCTitle() {
    const {
      currentProject: { creator_company: creator },
    } = this.props;
    const image = creator.logo && creator.logo.image;

    return [
      image && [
        {
          value: (
            <div className="logo-preview-container">
              <LogoUpload
                extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
                logos={[
                  {
                    preview: image.url,
                    url: image.url,
                  },
                ]}
                readOnly={true}
                viewMode="contain"
              />
            </div>
          ),
        },
      ],
      [
        {
          value: <div className="header-gc-title h1">{creator.name}</div>,
        },
      ],
    ].filter((component) => component);
  }
  renderOptionWithBadge = (badgeContent) => {
    return (
      <Badge
        badgeContent={badgeContent}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: this.props.darkMode ? '#d4fb00' : '#00b091',
            color: this.props.darkMode ? '#363a46' : 'white',
          },
        }}
      />
    );
  };

  renderSectionOption = (section) => {
    const { currentUser, currentProject, closeoutPercentage } = this.props;

    let label = '';
    let counter = null;
    let hasBadge = false;
    switch (section) {
      case ProjectDetails.DETAIL_SECTIONS.HOME:
        label = 'Home';
        break;
      case ProjectDetails.DETAIL_SECTIONS.DIRECTORY:
        label = 'Directory';
        break;
      /* case ProjectDetails.DETAIL_SECTIONS.SOV:
        label = this.projectApproved() ? 'SOV' : 'Proposal';
        break; */
      case ProjectDetails.DETAIL_SECTIONS.DOCUMENTS:
        label = 'Documents';
        counter = DocumentPermissions.canViewCount(currentUser, currentProject)
          ? currentProject.documents_count
          : null;
        break;
      case ProjectDetails.DETAIL_SECTIONS.PHOTOS:
        label = 'Photos';
        break;
      case ProjectDetails.DETAIL_SECTIONS.COIS:
        label = 'COIs';
        break;
      case ProjectDetails.DETAIL_SECTIONS.MEETINGS:
        label = 'Meetings';
        counter = currentProject.meetings_count;
        break;
      case ProjectDetails.DETAIL_SECTIONS.NEW_MEETINGS:
        label = 'Meetings';
        counter = currentProject.meetings_parents_count;
        hasBadge = true;
        break;
      case ProjectDetails.DETAIL_SECTIONS.RFAS:
        label = 'RFAs';
        counter = currentProject.rfas_count;
        break;
      case ProjectDetails.DETAIL_SECTIONS.RFIS:
        label = 'RFIs';
        counter = currentProject.rfis_count;
        break;
      case ProjectDetails.DETAIL_SECTIONS.SUBMITTALS:
        label = 'Submittals';
        counter = currentProject.submittals_count;
        break;
      case ProjectDetails.DETAIL_SECTIONS.SCHEDULE:
        label = 'Schedule';
        break;
      case ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS:
        label = 'Reporting';
        break;
      case ProjectDetails.DETAIL_SECTIONS.CLOSEOUT:
        label = 'Closeout';
        counter = `${closeoutPercentage}%`;
        break;
      case ProjectDetails.DETAIL_SECTIONS.PUNCH_LIST:
        label = 'Punch List';
        break;
      case ProjectDetails.DETAIL_SECTIONS.SECURITY_CAMERAS:
        if (!SecurityCameraPermissions.canView(currentUser, currentProject))
          return null;
        label = 'Security Cameras';
        break;
      default:
        break;
    }
    if (hasBadge)
      return {
        value: section,
        label: (
          <div className="project-details-option-with-badge">
            <div className="project-details-label-text">{label}</div>
            {this.renderOptionWithBadge('Beta')}
            {counter !== null && (
              <div className="project-details-mega-menu-item-counter">
                {counter}
              </div>
            )}
          </div>
        ),
      };
    return {
      value: section,
      label: (
        <React.Fragment>
          {label}
          {counter !== null && (
            <span className="mega-menu-item-counter">{counter}</span>
          )}
        </React.Fragment>
      ),
    };
  };

  renderSectionSelector() {
    const { navBarSelectedItem } = this.props;

    let selectedItem = navBarSelectedItem;
    if (navBarSelectedItem === 'Home') selectedItem = 'Project Tools';

    const categories = {
      'Project Information': [
        ProjectDetails.DETAIL_SECTIONS.HOME,
        ProjectDetails.DETAIL_SECTIONS.DIRECTORY,
        ProjectDetails.DETAIL_SECTIONS.SCHEDULE,
        ProjectDetails.DETAIL_SECTIONS.COIS,
        ProjectDetails.DETAIL_SECTIONS.CLOSEOUT,
      ]
        .map((o) => this.renderSectionOption(o))
        .filter((option) => option),
      Documentation: [
        ProjectDetails.DETAIL_SECTIONS.DOCUMENTS,
        ProjectDetails.DETAIL_SECTIONS.PHOTOS,
        ProjectDetails.DETAIL_SECTIONS.MEETINGS,
        ProjectDetails.DETAIL_SECTIONS.NEW_MEETINGS,
        ProjectDetails.DETAIL_SECTIONS.SUBMITTALS,
        ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS,
      ].map((o) => this.renderSectionOption(o)),
      Requests: [
        ProjectDetails.DETAIL_SECTIONS.RFAS,
        ProjectDetails.DETAIL_SECTIONS.RFIS,
      ].map((o) => this.renderSectionOption(o)),
      Misc: [
        ProjectDetails.DETAIL_SECTIONS.SECURITY_CAMERAS,
        ProjectDetails.DETAIL_SECTIONS.PUNCH_LIST,
      ]
        .map((o) => this.renderSectionOption(o))
        .filter((option) => option),
    };

    return (
      <MegaMenuDropdown
        type={DROPDOWN_TYPES.SECTION_SELECTOR}
        className="details-selector"
        options={[
          ProjectDetails.DETAIL_SECTIONS.HOME,
          ProjectDetails.DETAIL_SECTIONS.DIRECTORY,
          // ProjectDetails.DETAIL_SECTIONS.SOV,
          ProjectDetails.DETAIL_SECTIONS.DOCUMENTS,
          ProjectDetails.DETAIL_SECTIONS.PHOTOS,
          ProjectDetails.DETAIL_SECTIONS.COIS,
          ProjectDetails.DETAIL_SECTIONS.MEETINGS,
          ProjectDetails.DETAIL_SECTIONS.NEW_MEETINGS,
          ProjectDetails.DETAIL_SECTIONS.RFAS,
          ProjectDetails.DETAIL_SECTIONS.RFIS,
          ProjectDetails.DETAIL_SECTIONS.SUBMITTALS,
          ProjectDetails.DETAIL_SECTIONS.SCHEDULE,
          ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS,
          ProjectDetails.DETAIL_SECTIONS.CLOSEOUT,
          ProjectDetails.DETAIL_SECTIONS.PUNCH_LIST,
          ProjectDetails.DETAIL_SECTIONS.SECURITY_CAMERAS,
        ]
          .map(this.renderSectionOption)
          .filter((option) => option)}
        value={selectedItem}
        onChange={(option) => this.handleNavigationItemSelect(option.value)}
        categories={categories}
        megaMenuWidth={'50%'}
        customLabels={{ home: 'Project Tools' }}
      />
    );
  }

  renderContentHeaders(buttons = [], search = null, extraContent = null) {
    return (
      <SearchBar
        addButton={buttons}
        onSearchTextChange={search}
        onSearch={search}
      >
        {this.renderSectionSelector()}
        {extraContent}
      </SearchBar>
    );
  }

  renderSectionContent = (subsection, step) => {
    const renderHeaders = (buttons, search, extraContent) =>
      this.renderContentHeaders(buttons, search, extraContent);
    switch (this.props.navBarSelectedItem) {
      case ProjectDetails.DETAIL_SECTIONS.HOME:
        return this.props.showDashboard ? (
          <ProjectDashboard />
        ) : (
          <ProjectHome renderHeaders={renderHeaders} />
        );
      case ProjectDetails.DETAIL_SECTIONS.DIRECTORY:
        return <ProjectDirectory renderHeaders={renderHeaders} />;
      /* case ProjectDetails.DETAIL_SECTIONS.SOV:
        return <ProjectProposal renderHeaders={renderHeaders} />; */
      case ProjectDetails.DETAIL_SECTIONS.DOCUMENTS:
        return <ProjectDocumentation renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.PHOTOS:
        return <ProjectPhotos renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.COIS:
        return <CoiExps renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.MEETINGS:
        return <ProjectMeetingMinutes renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.NEW_MEETINGS:
        return <ProjectNewMeetings renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.RFIS:
        return <ProjectRfiLog renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.RFAS:
        return <ProjectRfaLog renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.SUBMITTALS:
        return <ProjectSubmittalLog renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.SCHEDULE:
        return <ProjectSchedule renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.DAILY_REPORTS:
        return (
          <ProjectDailyReportLog
            renderHeaders={renderHeaders}
            subsection={subsection}
            step={step}
          />
        );
      case ProjectDetails.DETAIL_SECTIONS.CLOSEOUT:
        return <ProjectCloseout renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.PUNCH_LIST:
        return <ProjectPunchList renderHeaders={renderHeaders} />;
      case ProjectDetails.DETAIL_SECTIONS.SECURITY_CAMERAS:
        return <ProjectSecurityCameras renderHeaders={renderHeaders} />;

      default:
        return null;
    }
  };

  render() {
    const {
      navBarSelectedItem,
      currentUser,
      currentProject,
      showPdfViewer,
      showLostOrCancelledModal,
      isCancelledModal,
      showDashboard,
      darkMode,
    } = this.props;

    return (
      currentProject && (
        <div
          className={`project-details ${darkMode ? 'dark-mode' : ''} ${
            navBarSelectedItem === ProjectDetails.DETAIL_SECTIONS.HOME &&
            showDashboard
              ? 'dashboard'
              : navBarSelectedItem
          }-project-details`}
        >
          <DetailsHeader
            leftHeader={{
              title: this.renderStatus(),
              value: currentProject.name,
            }}
            leftHeaderExtraComponents={this.renderHeaderButtons()}
            rightHeaders={this.renderHeaderGCTitle()}
          />
          {this.renderSectionContent()}

          {ProjectDashboardPermissions.canView(currentUser, currentProject) &&
            navBarSelectedItem === ProjectDetails.DETAIL_SECTIONS.HOME && (
              <Footer className="project-dashboard-switch">
                <Switch
                  checkedLabel="Dashboard"
                  unCheckedLabel="Project Home"
                  value={showDashboard}
                  onChange={this.handleShowDashboard}
                />
              </Footer>
            )}

          <ProjectOpenItemsModal />

          <ProjectOpenItemsReportModal />

          {showPdfViewer && (
            <PdfViewer
              content={{
                source: this.state.pdf_url,
                type: 'url',
              }}
              show={showPdfViewer}
              onClose={this.handlePdfViewerClose}
            />
          )}
          {showLostOrCancelledModal && (
            <ProjectCancelledOrLostModal
              statusSelected={isCancelledModal ? 'cancelled' : 'lost'}
              showLostOrCancelledModal={showLostOrCancelledModal}
              projectId={currentProject.id}
              terminationDate={currentProject.termination_date}
              terminationReason={currentProject.termination_reason}
              projectTerminationCategory={currentProject.termination_category}
            />
          )}
        </div>
      )
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      currentProject: getCurrentProject(state),
      navBarSelectedItem: getNavBarSelectedItem(state),
      showDashboard: getShowDashboard(state),
      currentUser: getCurrentUser(state),
      projectOpenItemsCount: getProjectOpenItemsCount(state),
      projectOpenItemsCritCount: getProjectOpenItemsCritCount(state),
      closeoutPercentage: getCloseoutPercentage(state),
      subDailyReportsCount: getSubDailyReportsCount(state),
      showPdfViewer: getShowPdfViewer(state),
      showLostOrCancelledModal: getShowLostOrCancelledModal(state),
      isCancelledModal: getIsCancelledModal(state),
      darkMode: getDarkMode(state),
    };
  })(ProjectDetails)
);
