import { punchListApi } from 'services/api/punch-list-api.js';
import { punchListDataToForm } from 'services/utils/punch-list';
import { showErrorMessage } from 'actions/messages';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import {
  PUNCH_LIST_SET_ITEMS,
  PUNCH_LIST_SET_FILTERS,
  PUNCH_LIST_SET_SHOW_FILTERS,
  PUNCH_LIST_SET_SHOW_MODAL,
  PUNCH_LIST_SET_FORM,
  PUNCH_LIST_RESET_FORM,
  PUNCH_LIST_SET_PDF,
  PUNCH_LIST_SET_READONLY,
  PUNCH_LIST_ADD_ITEM,
  PUNCH_LIST_SET_NOTES,
  PUNCH_LIST_ADD_NOTE,
  SET_PUNCH_LIST_SHOW_LOCATIONS,
  SET_SHOW_PUNCH_LIST_PDF_VIEWER,
  PUNCH_LIST_UPDATE_ITEM_SUCCESS,
  DELETE_ITEM_SUCCESFULLY,
} from './action-types';

export const setShowPunchListFilters = (show) => ({
  type: PUNCH_LIST_SET_SHOW_FILTERS,
  payload: { show },
});

export const setShowPunchListModal = (show) => ({
  type: PUNCH_LIST_SET_SHOW_MODAL,
  payload: { show },
});

export const setPunchListShowLocations = (show) => {
  return { type: SET_PUNCH_LIST_SHOW_LOCATIONS, payload: show };
};

export const setPunchListFilters = (filters = {}) => ({
  type: PUNCH_LIST_SET_FILTERS,
  payload: { filters },
});

export const resetPunchListForm = () => ({
  type: PUNCH_LIST_RESET_FORM,
});

export const setPunchListForm = (form = {}) => ({
  type: PUNCH_LIST_SET_FORM,
  payload: { form },
});

export const setPunchListItems = (items = [], total = 0, page = 1) => ({
  type: PUNCH_LIST_SET_ITEMS,
  payload: { items, total, page },
});

export const setPunchListPdf = (pdf = null) => ({
  type: PUNCH_LIST_SET_PDF,
  payload: { pdf },
});

export const setPunchListReadOnly = (readOnly = true) => ({
  type: PUNCH_LIST_SET_READONLY,
  payload: { readOnly },
});

export const addPunchListItem = (item) => ({
  type: PUNCH_LIST_ADD_ITEM,
  payload: { item },
});

export const setPunchListNotes = (notes) => ({
  type: PUNCH_LIST_SET_NOTES,
  payload: { notes },
});

export const addPunchListNote = (note) => ({
  type: PUNCH_LIST_ADD_NOTE,
  payload: { note },
});

export const updatePunchListItemSuccessfully = (item) => ({
  type: PUNCH_LIST_UPDATE_ITEM_SUCCESS,
  payload: item,
});

export const setPunchListPdfViewer = (showPdfViewer) => ({
  type: SET_SHOW_PUNCH_LIST_PDF_VIEWER,
  payload: showPdfViewer,
});

export const deletePunchListItemSuccessfully = (itemId) => ({
  type: DELETE_ITEM_SUCCESFULLY,
  payload: itemId,
});
export const loadPunchListItems = (projectId, filters) => {
  return (dispatch) => {
    return punchListApi.getItems(projectId, filters).then((response) => {
      if (response.ok) {
        const { data, total } = response;
        dispatch(setPunchListItems(data, total, filters.page));
        dispatch(setPunchListFilters(filters));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
};

export const loadPunchListItem = (projectId, itemId) => {
  return (dispatch) => {
    return punchListApi.getItem(projectId, itemId).then((response) => {
      if (response.ok) {
        const form = punchListDataToForm(response.data);
        dispatch(setPunchListForm(form));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
};

export const createPunchListItem = (projectId, data) => {
  return (dispatch) => {
    return punchListApi.createItem(projectId, data).then((response) => {
      if (response.ok) {
        dispatch(addPunchListItem(response.data));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
};

export const updatePunchListItem = (projectId, itemId, data) => {
  return (dispatch) => {
    return punchListApi.updateItem(projectId, itemId, data).then((response) => {
      if (response.ok) {
        dispatch(updatePunchListItemSuccessfully(response.data));
      } else {
        dispatch(showErrorMessage(response.errors));
        throw new Error('Update failed');
      }
    });
  };
};

export const deletePunchListItem = (projectId, itemId) => {
  return (dispatch) => {
    return punchListApi.deleteItem(projectId, itemId).then((response) => {
      if (response.ok) {
        dispatch(deletePunchListItemSuccessfully(itemId));
      } else {
        dispatch(showErrorMessage(response.errors));
        throw new Error('Update failed');
      }
    });
  };
};

export const createPunchListDraft = (projectId, data) => {
  return (dispatch) => {
    return punchListApi.createDraft(projectId, data).then((response) => {
      if (response.ok) {
        dispatch(addPunchListItem(response.data));
        // dispatch(resetPunchListForm());
      } else {
        dispatch(showErrorMessage(response.errors));
        // dispatch(setPunchListForm({ errors: response.errors }));
      }
      return response;
    });
  };
};

export const loadPunchListPdf = (projectId) => {
  return (dispatch) => {
    return punchListApi.getItemsPdf(projectId).then((response) => {
      if (response.ok) {
        dispatch(setPunchListPdf(response.data));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
};

export function loadPunchListNotes(itemId) {
  return (dispatch) => {
    return punchListApi.getNotes(itemId).then((response) => {
      if (response.ok) {
        dispatch(setPunchListNotes(response.notes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function createPunchListNote(itemId, content) {
  return (dispatch) => {
    return punchListApi.createNote(itemId, content).then((response) => {
      if (response.ok) {
        dispatch(addPunchListNote(response.note));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function markPunchListNotesRead(itemId) {
  return (dispatch) => {
    return punchListApi.markNotesRead(itemId).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));
      return response;
    });
  };
}

export const sendPunchListEmail = (
  projectId,
  punchListId,
  recipients,
  subject,
  message
) => {
  return (dispatch) => {
    return punchListApi
      .email(projectId, punchListId, recipients, subject, message)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(setShowEmailModal(false));
        }

        return response;
      });
  };
};
