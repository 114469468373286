import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import './PunchListSearchFilter.css';

import Input from 'components/shared/input/Input';
import { TYPING_TIMEOUT } from 'components/shared/navigation/SearchBar';

import { setShowPunchListFilters } from 'components/admin/projects/details/project-punch-list/store/actions';
import { getPunchListFilters } from 'components/admin/projects/details/project-punch-list/store/selectors';
import Button, {
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

const PunchListSearchFilter = (props) => {
  const { handleFilterChange: hfc, currentFilters, dispatch } = props;
  const [value, setValue] = useState(currentFilters.search);

  const debouncedTextChange = useDebouncedCallback(
    (value) => hfc({ search: value }),
    TYPING_TIMEOUT
  );

  const handleValueChange = (value) => {
    setValue(value);
    debouncedTextChange(value);
  };

  const handleClick = () => dispatch(setShowPunchListFilters(true));

  return (
    <div className="punch-list-search-filter">
      <Input placeholder="Search" value={value} onChange={handleValueChange} />
      <Button
        icon={BUTTON_ICONS.FILTER}
        color={BUTTON_COLORS.WHITE}
        onClick={handleClick}
      />
    </div>
  );
};

export default connect((state) => {
  return {
    currentFilters: getPunchListFilters(state),
  };
})(PunchListSearchFilter);
