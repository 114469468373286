import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Collapse,
  Divider,
  IconButton,
  List,
  MenuItem,
  Select,
} from '@mui/material';
import { getDarkMode } from 'selectors/theme';
import colors from 'styles/colors';

import remove from 'images/cancel.svg';
import removeDark from 'images/cancel-dark.svg';
import arrow from 'images/arrow.svg';
import arrowDark from 'images/arrow-dark.svg';
import arrowGreen from 'images/arrow-green.svg';
import arrowNeon from 'images/arrow-neon.svg';
import './Selector.css';

const Selector = (props) => {
  const {
    multiple,
    options,
    disabled,
    readOnly,
    placeholder,
    className,
    darkMode,
    onChange,
    value: v,
    hideUnderline,
    valid,
    clearable,
    arrowIcon,
    arrowIconDark,
    optionFontWeight,
  } = props;

  const selectStyles = {
    '&.MuiInputBase-root': {
      width: '100%',
      height: 40,
      '& .MuiSelect-select': {
        paddingLeft: hideUnderline ? 0 : 1,
        '&.MuiInputBase-input': {
          paddingRight: clearable ? 5 : 3,
        },
        '&:focus': { backgroundColor: 'transparent' },
      },
      '&:before': {
        borderWidth: hideUnderline ? 0 : 1,
        borderColor: darkMode
          ? colors['dark-mode-light-white']
          : colors['light-mode-dark-black'],
      },
      '&:after': { borderWidth: 1, transition: 'none' },
      '& .placeholder': {
        color: colors[darkMode ? 'white-text' : 'light-mode-black-text'],
        opacity: 0.7,
      },
      '&.Mui-error:before': {
        borderColor: darkMode ? colors['chart-blue'] : colors['chart-red'],
      },
      '&:hover': {
        '&:not(.Mui-disabled, .Mui-error)': {
          '&:before': {
            borderWidth: hideUnderline ? 0 : 1,
            borderColor: darkMode
              ? colors['dark-mode-light-white']
              : colors['light-mode-dark-black'],
          },
          '&:after': { display: 'none' },
        },
      },
      '&.Mui-disabled:before': { borderBottomStyle: 'solid' },
      '&.Mui-focused:after': { borderWidth: hideUnderline ? 0 : 1 },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 0.30em)',
        width: 11,
        height: 11,
        right: hideUnderline ? 0 : 8,
      },
    },
  };
  const menuStyles = {
    '& .MuiPopover-paper': {
      borderRadius: 0,
      maxHeight: 224,
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.15)',
    },
    '& .MuiMenu-list': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiMenuItem-root': {
        padding: 1.25,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        '&.Mui-selected': { backgroundColor: 'transparent' },
        '&.selected': { color: colors[darkMode ? 'neon' : 'primary-green'] },
        '&.has-suboptions': { justifyContent: 'space-between' },
        '&.suboption': {
          paddingLeft: 2.5,
          paddingRight: 2.5,
          backgroundColor:
            colors[darkMode ? 'dark-mode-light-grey' : 'light-grey'],
        },
        '&.Mui-focusVisible': { backgroundColor: 'transparent' },
      },
    },
  };
  const dividerStyles = {
    '&.MuiDivider-root': {
      margin: 0,
      opacity: 0.65,
      marginLeft: 0.75,
      width: 'calc(100% - 12px)',
      '&.selected': {
        borderColor: darkMode ? colors['neon'] : colors['primary-green'],
      },
    },
  };

  const [submenuOpen, setSubmenuOpen] = useState(null);

  // select current value
  const getSelectValue = () => {
    if (!v) return multiple ? [] : '';
    else if (typeof v === 'object' && Object.keys(v).includes('mainValue')) {
      return !v.mainValue ? (multiple ? [] : '') : v.mainValue;
    } else return v;
  };
  const selectValue = getSelectValue();

  // component current value
  const getValue = () => {
    if (!v) return multiple ? [] : '';
    else if (typeof v === 'object' && Object.keys(v).includes('mainValue')) {
      return {
        mainValue: !v.mainValue ? (multiple ? [] : '') : v.mainValue,
        subValue: !v.subValue ? (multiple ? [] : '') : v.subValue,
      };
    } else return v;
  };
  const value = getValue();

  // checks if option is selected
  const isSelected = (value, optValue, isSuboption = false) => {
    if (!value || value.length === 0) return false;

    if (Array.isArray(value)) {
      const f = value.find((val) => {
        return typeof val === 'object'
          ? val.value === optValue
          : val === optValue;
      });
      return !!f;
    } else if (typeof value === 'object') {
      if (Object.keys(value).includes('mainValue')) {
        if (isSuboption)
          return isSelected(value.subValue, optValue, isSuboption);
        else return isSelected(value.mainValue, optValue);
      } else return value.value === optValue;
    } else return value === optValue;
  };

  // builds returned value
  const buildReturnedValue = (value, option, subOption = null) => {
    if (value && Array.isArray(value)) {
      // multiple
      const findIndex = value.findIndex((v) => {
        if (typeof v === 'object') return v.value === option.value;
        else return v === option.value;
      });

      if (findIndex >= 0 && !subOption)
        return value.filter((_, i) => i !== findIndex);
      else if (findIndex < 0) return [...value, option];
      else return value;
    } else if (
      value &&
      typeof value === 'object' &&
      Object.keys(value).includes('mainValue')
    ) {
      return {
        mainValue: buildReturnedValue(value.mainValue, option, subOption),
        subValue: !!subOption
          ? buildReturnedValue(value.subValue, subOption)
          : multiple
          ? []
          : null,
      };
    } else {
      // single value
      return option;
    }
  };

  const getOption = (value, opts = options) => {
    return opts.find((o) => o.value === value);
  };
  const handleSelectOption = (event) => {
    const newValue = event.target.value;
    const option = getOption(
      multiple ? newValue[newValue.length - 1] : newValue
    );

    const res = buildReturnedValue(value, option);
    onChange(res);
  };

  const handleSelectSuboption = (option, subOption) => {
    const res = buildReturnedValue(value, option, subOption);
    onChange(res);
  };

  const getShowClear = () => {
    let res = clearable && !readOnly && !disabled;
    if (res) {
      if (!value || value.length === 0) res = false;
      else if (
        typeof value === 'object' &&
        Object.keys(value).includes('mainValue') &&
        (!value.mainValue || value.mainValue.length === 0)
      )
        res = false;
    }
    return res;
  };
  const showClear = getShowClear();
  const handleClear = (event) => {
    event.preventDefault();

    let res = null;
    if (typeof value === 'object' && Object.keys(value).includes('mainValue')) {
      res = { mainValue: multiple ? [] : null, subValue: multiple ? [] : null };
    } else if (multiple) res = [];

    onChange(res);
  };

  const renderPlaceholder = () => (
    <span className="placeholder">{placeholder}</span>
  );

  const renderValue = (value, opts = options) => {
    if (!value || value === null || value.length === 0)
      return renderPlaceholder();
    if (typeof value === 'object' && Object.keys(value).includes('mainValue')) {
      if (
        (!Array.isArray(value.subValue) && value.subValue) ||
        value.subValue.length > 0
      ) {
        const newOpts = opts.reduce((acc, curr) => {
          return !!curr.subOptions ? acc.concat(curr.subOptions) : acc;
        }, []);
        return renderValue(value.subValue, newOpts);
      } else return renderValue(value.mainValue);
    } else if (Array.isArray(value)) {
      return value
        .map((v) =>
          typeof v === 'object' ? v.label : getOption(v, opts).label
        )
        .join(', ');
    } else if (typeof value === 'object') return value.label;
    else {
      const option = getOption(value, opts);
      return (
        <span style={{ fontWeight: optionFontWeight }}>
          {option ? option.label : renderPlaceholder()}
        </span>
      );
    }
  };

  const renderIcon = ({ className }) => (
    <img
      className={`selector-arrow ${className}`}
      src={!darkMode ? arrowIcon : arrowIconDark}
      alt={'arrow'}
    />
  );

  const renderItem = (o, showDivider = true, parent = null) => {
    const isOpen = o.value === submenuOpen;

    const hasSuboptions = !!o.subOptions;
    const isSuboption = !!parent;

    const selected = isSelected(value, o.value, isSuboption);

    const handleClick = (e) => {
      e.stopPropagation();
      setSubmenuOpen(isOpen ? null : o.value);
    };

    return [
      <MenuItem
        value={o.value}
        key={`option_${o.value}`}
        className={`${selected ? 'selected' : ''} ${
          isSuboption ? 'suboption' : ''
        } ${hasSuboptions ? 'has-suboptions' : ''} ${o.className || ''}`}
        selected={selected}
        disableRipple
        onClick={
          isSuboption ? () => handleSelectSuboption(parent, o) : undefined
        }
      >
        {o.label}
        {hasSuboptions && (
          <IconButton
            color="primary"
            disableRipple
            disableFocusRipple
            onClick={handleClick}
          >
            <img
              className="menu-item-arrow"
              src={darkMode ? arrowNeon : arrowGreen}
              alt="arrow"
            />
          </IconButton>
        )}
      </MenuItem>,
      showDivider && (
        <Divider
          sx={dividerStyles}
          className={selected && !isOpen ? 'selected' : ''}
          key={`option_${o.value}_divider`}
        />
      ),
      hasSuboptions && (
        <Collapse
          in={isOpen}
          unmountOnExit
          key={`option_${o.value}_suboptions`}
        >
          <List component="div" disablePadding>
            {o.subOptions.map((opt, j, arr) =>
              renderItem(opt, showDivider || j < arr.length - 1, o)
            )}
          </List>
        </Collapse>
      ),
    ];
  };

  const filteredOptions = options.filter(
    (o) => typeof o.show === 'undefined' || o.show
  );

  return (
    <div className={`selector ${className}`}>
      <Select
        variant="standard"
        multiple={multiple}
        disabled={disabled}
        readOnly={readOnly}
        displayEmpty
        value={selectValue}
        onChange={handleSelectOption}
        sx={selectStyles}
        MenuProps={{
          sx: menuStyles,
          className: `selector-menu ${className ? className + '-menu' : ''}`,
        }}
        renderValue={() => renderValue(value)}
        IconComponent={renderIcon}
        error={!valid}
      >
        {filteredOptions.map((o, i, arr) => renderItem(o, i < arr.length - 1))}
        {filteredOptions.length === 0 && <MenuItem disabled>No items</MenuItem>}
      </Select>
      {showClear && (
        <img
          className={`selector-remove ${hideUnderline && 'hidden-underline'}`}
          src={darkMode ? removeDark : remove}
          alt="remove"
          onClick={handleClear}
        />
      )}
    </div>
  );
};

const OptionProptype = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  subOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
    })
  ),
  className: PropTypes.string,
  show: PropTypes.bool,
});

const ValueProptype = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  OptionProptype,
]);
const MultipleValueProptype = PropTypes.arrayOf(ValueProptype);
const ValueProptypes = PropTypes.oneOfType([
  MultipleValueProptype,
  ValueProptype,
]);

const SubValueProptype = PropTypes.shape({
  mainValue: ValueProptypes,
  subValue: ValueProptypes,
});

Selector.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(OptionProptype).isRequired,
  value: PropTypes.oneOfType([
    ValueProptype,
    MultipleValueProptype,
    SubValueProptype,
  ]),
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  darkMode: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  arrowIcon: PropTypes.node,
  arrowIconDark: PropTypes.node,
  optionFontWeight: PropTypes.number,
};

Selector.defaultProps = {
  className: '',
  multiple: false,
  options: [],
  onChange: () => {},
  readOnly: false,
  disabled: false,
  valid: true,
  clearable: false,
  hideUnderline: false,
  arrowIcon: arrow,
  arrowIconDark: arrowDark,
  optionFontWeight: 400,
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(Selector);
