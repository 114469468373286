import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  todayValidation,
  calculateDateDuration,
} from 'services/utils/date-util';

import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import Selector from 'components/shared/selector/Selector';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import DatePicker from 'components/shared/date-picker/DatePicker';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import DocumentUploadWithPreview from 'components/shared/document-upload/DocumentUploadWithPreview';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { getDarkMode } from 'selectors/theme';

import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import {
  getProjectProposal,
  getCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/selectors';

import useDirectory from 'components/admin/projects/details/project-directory/Directory.hook';
import usePunchListForm from './PunchListForm.hook';
import { getPunchListForm, getPunchListReadOnly } from './store/selectors';
import {
  itemTypeOptions,
  itemPriorityOptions,
  itemStatusOptions,
} from 'domain/punch-list';
import './PunchListForm.css';
import { Grid, Typography } from '@mui/material';
import SelectorProjectSites from '../project-site-area/SelectorProjectSites';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  createPunchListDraft,
  createPunchListItem,
  resetPunchListForm,
  setPunchListForm,
  updatePunchListItem,
} from './store/actions';

const PunchListForm = (props) => {
  const { readOnly, form } = props;
  const { onCancel, onDraft, onSubmit, onFormChange } = usePunchListForm(props);
  const { loadContributors, tradeCodeOptions, recipientOptions } = useDirectory(
    props
  );

  const transformAssignees = (assignees) =>
    assignees.map((assignee) => [assignee.id, assignee.label]);

  const transformProjectSite = (site) => ({
    value: site.id,
    label: `${site.name} - #${site.number}`,
  });

  useEffect(() => {
    if (props.form.project_site && props.form.project_site.id) {
      onFormChange({
        project_site: transformProjectSite(props.form.project_site),
      });

      if (
        props.form.assignees &&
        props.form.assignees.length > 0 &&
        typeof props.form.assignees[0] === 'object'
      ) {
        onFormChange({ assignees: transformAssignees(props.form.assignees) });
      }
    }
  }, [props.form.project_site]);
  const TypeInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.item_type) && 'Type'}
      control={
        <Selector
          value={form.item_type}
          onChange={(item_type) => onFormChange({ item_type })}
          placeholder={!readOnly ? 'Type' : '--'}
          className="select-dropdown"
          options={itemTypeOptions}
          clearable
          readOnly={readOnly}
          hideUnderline={readOnly}
          valid={!(form.errors && form.errors.item_type)}
        />
      }
    />
  );
  const PriorityInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.priority) && 'Priority'}
      control={
        <Selector
          value={form.priority}
          onChange={(priority) => onFormChange({ priority })}
          placeholder={!readOnly ? 'Priority' : '--'}
          className="select-dropdown"
          options={itemPriorityOptions}
          clearable
          readOnly={readOnly}
          hideUnderline={readOnly}
          valid={!(form.errors && form.errors.priority)}
        />
      }
    />
  );
  const TitleInput = (
    <FormControlBlock
      hideBottomLabel
      label={(readOnly || form.title) && 'Title'}
      control={
        <CharsCounterTextarea
          value={form.title}
          onChange={(title) => onFormChange({ title })}
          placeholder={!readOnly ? 'Title' : '--'}
          className="title"
          limit={50}
          readOnly={readOnly}
          valid={!(form.errors && form.errors.title)}
        />
      }
    />
  );
  const DueDateInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.due_date) && 'Due Date'}
      bottomLabel={
        form.due_date && todayValidation(moment(form.due_date))
          ? calculateDateDuration(moment(), form.due_date) + ' left'
          : '0 Days left'
      }
      control={
        <DatePicker
          value={form.due_date}
          onChange={(due_date) => onFormChange({ due_date })}
          placeholder={!readOnly ? 'Due Date' : '--'}
          className="due-date"
          hideAdornment={readOnly}
          isValidDate={todayValidation}
          clearable
          readOnly={readOnly}
          valid={!(form.errors && form.errors.due_date)}
        />
      }
    />
  );
  const statusOptionsWithoutDraft = itemStatusOptions.filter(
    (s) => s.value !== 'draft'
  );
  const StatusInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label="Status"
      control={
        <Selector
          value={form.status}
          onChange={(status) => onFormChange({ status })}
          placeholder={!readOnly ? 'Status' : '--'}
          className="select-dropdown"
          options={
            form.status === 'draft'
              ? itemStatusOptions
              : statusOptionsWithoutDraft
          }
          clearable
          readOnly={readOnly}
          hideUnderline={readOnly}
          valid={!(form.errors && form.errors.status)}
        />
      }
    />
  );

  const TradeCodeInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.csi_code) && 'Cost Code'}
      control={
        <Selector
          value={form.csi_code}
          onChange={(csi_code) => onFormChange({ csi_code })}
          placeholder={!readOnly ? 'Cost Code' : '--'}
          className="select-dropdown"
          options={tradeCodeOptions}
          readOnly={readOnly}
          hideUnderline={readOnly}
          valid={!(form.errors && form.errors.csi_code)}
        />
      }
    />
  );
  const SiteInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.project_site) && 'Location'}
      control={
        <SelectorProjectSites
          value={form.project_site}
          onChange={(project_site) => onFormChange({ project_site })}
          placeholder={!readOnly ? 'Location' : '--'}
          className="select-dropdown"
          hideUnderline={readOnly}
          readOnly={readOnly}
          valid={!(form.errors && form.errors.project_site)}
        />
      }
    />
  );

  const ManagerInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.manager) && 'Manager'}
      control={
        <SearchableDropDownPaginated
          value={form.manager}
          onChange={(manager) => onFormChange({ manager })}
          placeholder={!readOnly ? 'Manager' : '--'}
          loadOptions={loadContributors}
          defaultArrow={true}
          clearable
          readOnly={readOnly}
          valid={!(form.errors && form.errors.manager)}
        />
      }
    />
  );

  const ApproverInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.approver) && 'Approver'}
      control={
        <SearchableDropDownPaginated
          value={form.approver}
          onChange={(approver) => onFormChange({ approver })}
          placeholder={!readOnly ? 'Approver' : '--'}
          loadOptions={loadContributors}
          defaultArrow={true}
          clearable
          readOnly={readOnly}
          valid={!(form.errors && form.errors.approver)}
        />
      }
    />
  );

  const AssigneesInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.assignees.length > 0) && 'Assignees'}
      control={
        !readOnly ? (
          <MultipleDropdown
            currentValues={form.assignees}
            onChange={(assignees) => onFormChange({ assignees })}
            placeholder="Assignees"
            className="recipients-dropdown"
            options={recipientOptions}
            valid={!(form.errors && form.errors.assignees)}
          />
        ) : (
          <ReadOnlyInput
            value={
              form.assignees.length > 0
                ? form.assignees.map((p) => p[1]).join(', ')
                : '--'
            }
          />
        )
      }
    />
  );

  const DescriptionInput = (
    <FormControlBlock
      hideBottomLabel={readOnly}
      label={(readOnly || form.description) && 'Remarks'}
      control={
        <TextareaAutosizeWrapper
          initialValue={form.description}
          onChange={(description) => onFormChange({ description })}
          placeholder={readOnly ? '--' : 'Remarks'}
          className="request-textarea"
          disabled={readOnly}
          readOnly={readOnly}
          valid={!(form.errors && form.errors.description)}
        />
      }
    />
  );

  const handleRemoveDocument = (index) => {
    const documents = [...form.documents];
    const deleted_documents = [
      ...form.deleted_documents,
      ...documents.splice(index, 1),
    ];

    onFormChange({ documents, deleted_documents });
  };
  const handleUploadDocument = (files) => {
    const documents = [...form.documents];
    files.forEach((file) => {
      documents.push({ file, name: file.name });
    });
    onFormChange({ documents });
  };

  const setImages = (files) => {
    let photos = [...form.photos];
    files.forEach(function (val) {
      photos.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });
    onFormChange({ photos: photos });
  };

  const removeImages = (index) => {
    let photos = [...form.photos];
    let deleted_photos = [...form.deleted_photos];
    let deletedImage = photos.splice(index, 1);
    deleted_photos.push(deletedImage[0]);
    onFormChange({
      photos,
      deleted_photos,
    });
  };

  const DocumentsInput = (
    <FormControlBlock
      hideBottomLabel
      label="Documents"
      control={
        <DocumentUploadWithPreview
          multiple
          readOnly={readOnly}
          documents={form.documents}
          onDrop={(docs) => handleUploadDocument(docs)}
          onRemove={(index) => handleRemoveDocument(index)}
        />
      }
    />
  );

  const ImagesInput = (
    <FormControlBlock
      hideBottomLabel
      label="Photos"
      control={
        <ImageUpload
          extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
          images={form.photos}
          readOnly={readOnly}
          onRemove={(index) => removeImages(index)}
          onUpload={setImages}
        />
      }
    />
  );

  return (
    <>
      <Grid
        className="punch-list-form"
        container
        columnSpacing={3}
        rowSpacing={1}
      >
        <Grid item xs={12}>
          <Typography color="text.primary" variant="h6">
            Information
          </Typography>
        </Grid>
        <Grid item xs={readOnly ? 9 : 12}>
          {TitleInput}
        </Grid>
        {/* {readOnly && ( */}
        <Grid item xs={4}>
          {StatusInput}
        </Grid>
        {/* )} */}
        <Grid item xs={4}>
          {TypeInput}
        </Grid>
        <Grid item xs={4}>
          {PriorityInput}
        </Grid>
        <Grid item xs={4}>
          {TradeCodeInput}
        </Grid>
        <Grid item xs={4}>
          {DueDateInput}
        </Grid>
        <Grid item xs={4}>
          {SiteInput}
        </Grid>
        <Grid item xs={12}>
          <Typography color="text.primary" variant="h6">
            Participants
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {ManagerInput}
        </Grid>
        <Grid item xs={6}>
          {ApproverInput}
        </Grid>
        <Grid item xs={12}>
          {AssigneesInput}
        </Grid>
        <Grid item xs={12}>
          <Typography color="text.primary" variant="h6">
            Description
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {DescriptionInput}
        </Grid>
        <Grid item xs={6}>
          {DocumentsInput}
        </Grid>
        <Grid item xs={6}>
          {ImagesInput}
        </Grid>
      </Grid>

      <div className="punch-list-actions">
        {Object.keys(form.errors).length > 0 && (
          <h5 className="error-message">
            There are missing fields, please review.
          </h5>
        )}
        <div className="punch-list-form-actions">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={onCancel}
          />
          {(form.status === 'draft' || !form.id) && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Save Draft"
              onClick={onDraft}
            />
          )}
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Submit'}
            onClick={onSubmit}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
  readOnly: getPunchListReadOnly(state),
  form: getPunchListForm(state),
  directory: getDirectory(state),
  projectProposal: getProjectProposal(state),
  csiCodes: getCsiCodes(state),
  darkMode: getDarkMode(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      setPunchListForm,
      resetPunchListForm,
      createPunchListItem,
      createPunchListDraft,
      updatePunchListItem,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PunchListForm)
);
