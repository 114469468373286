import { createSceneReducer } from 'reducers/reducers-util';
import { combineReducers } from 'redux';
import projectsDataReducer from 'components/admin/projects/store/reducers.data';
import projectsUiReducer from 'components/admin/projects/store/reducers.ui';
import newProjectsDataReducer from 'components/admin/projects/creation/store/reducers.data';
import newProjectsUiReducer from 'components/admin/projects/creation/store/reducers.ui';
import projectDetailsDataReducer from 'components/admin/projects/details/store/reducers.data';
import projectDetailsUiReducer from 'components/admin/projects/details/store/reducers.ui';
import projectProposalDataReducer from 'components/admin/projects/details/project-proposal/store/reducers.data';
import projectProposalUiReducer from 'components/admin/projects/details/project-proposal/store/reducers.ui';
import projectDirectoryDataReducer from 'components/admin/projects/details/project-directory/store/reducers.data';
import projectDirectoryUiReducer from 'components/admin/projects/details/project-directory/store/reducers.ui';
import projectMeetingMinutesDataReducer from 'components/admin/projects/details/project-meeting-minutes/store/reducers.data';
import projectMeetingMinutesUiReducer from 'components/admin/projects/details/project-meeting-minutes/store/reducers.ui';
import projectNewMeetingsModuleDataReducer from 'components/admin/projects/details/project-new-meetings-module/store/reducers.data';
import projectNewMeetingsModuleUiReducer from 'components/admin/projects/details/project-new-meetings-module/store/reducers.ui';
import projectRfiLogDataReducer from 'components/admin/projects/details/project-rfi-log/store/reducers.data';
import projectRfiLogUiReducer from 'components/admin/projects/details/project-rfi-log/store/reducers.ui';
import projectRfaLogDataReducer from 'components/admin/projects/details/project-rfa-log/store/reducers.data';
import projectRfaLogUiReducer from 'components/admin/projects/details/project-rfa-log/store/reducers.ui';
import projectSubmittalLogDataReducer from 'components/admin/projects/details/project-submittal-log/store/reducers.data';
import projectSubmittalLogUiReducer from 'components/admin/projects/details/project-submittal-log/store/reducers.ui';
import projectEmailModalUiReducer from 'components/admin/projects/details/project-email-modal/store/reducers.ui';
import projectEmailModalDataReducer from 'components/admin/projects/details/project-email-modal/store/reducers.data';
import projectDailyReportLogDataReducer from 'components/admin/projects/details/project-daily-report-log/store/reducers.data';
import projectDailyReportLogUiReducer from 'components/admin/projects/details/project-daily-report-log/store/reducers.ui';
import projectDocumentationDataReducer from 'components/admin/projects/details/project-documentation/store/reducers.data';
import projectOpenItemsDataReducer from 'components/admin/projects/details/project-open-items/store/reducers.data';
import projectOpenItemsUiReducer from 'components/admin/projects/details/project-open-items/store/reducers.ui';
import projectCloseoutDataReducer from 'components/admin/projects/details/project-closeout/store/reducers.data';
import usersNotificationModalUiReducer from 'components/shared/pdf-viewer-bluebeam/store/reducers.ui';
import projectSettingsDataReducer from 'components/admin/projects/details/project-settings/store/reducers.data';
import projectCoiExpsDataReducer from 'components/admin/projects/details/project-coi-exps/store/reducers.data';
import projectPhotosDataReducer from 'components/admin/projects/details/project-photos/store/reducers.data';
import projectPhotosUiReducer from 'components/admin/projects/details/project-photos/store/reducers.ui';
import projectDashboardDataReducer from 'components/admin/projects/details/project-dashboard/store/reducers.data';
import projectDashboardUiReducer from 'components/admin/projects/details/project-dashboard/store/reducers.ui';
import projectFieldReportDataReducer from 'components/admin/projects/details/project-daily-report-log/field-reports/store/reducers.data';
import projectFieldReportUiReducer from 'components/admin/projects/details/project-daily-report-log/field-reports/store/reducers.ui';
import projectSitesDataReducer from 'components/admin/projects/details/project-site-area/store/reducers.data';
import projectSitesUiReducer from 'components/admin/projects/details/project-site-area/store/reducers.ui';
import projectScheduleUiReducer from 'components/admin/projects/details/project-schedule/store/reducers.ui';
import projectScheduleDataReducer from 'components/admin/projects/details/project-schedule/store/reducers.data';
import projectTagsDataReducer from 'components/admin/projects/details/project-tags/store/reducers.data';
import projectSecurityCameraDataReducer from 'components/admin/projects/details/project-security-camera/store/reducers.data';
import projectSecurityCameraUiReducer from 'components/admin/projects/details/project-security-camera/store/reducers.ui';
import notificationSettingsReducer from 'components/shared/notification-settings/store/reducer.data';
import punchListDataReducer from 'components/admin/projects/details/project-punch-list/store/reducers.data';
import punchListUiReducer from 'components/admin/projects/details/project-punch-list/store/reducers.ui';

export default createSceneReducer(
  projectsDataReducer,
  projectsUiReducer,
  combineReducers({
    creation: createSceneReducer(newProjectsDataReducer, newProjectsUiReducer),
    details: createSceneReducer(
      projectDetailsDataReducer,
      projectDetailsUiReducer,
      combineReducers({
        proposal: createSceneReducer(
          projectProposalDataReducer,
          projectProposalUiReducer
        ),
        directory: createSceneReducer(
          projectDirectoryDataReducer,
          projectDirectoryUiReducer
        ),
        meetingMinutes: createSceneReducer(
          projectMeetingMinutesDataReducer,
          projectMeetingMinutesUiReducer
        ),
        newMeetings: createSceneReducer(
          projectNewMeetingsModuleDataReducer,
          projectNewMeetingsModuleUiReducer
        ),

        rfiLog: createSceneReducer(
          projectRfiLogDataReducer,
          projectRfiLogUiReducer
        ),
        rfaLog: createSceneReducer(
          projectRfaLogDataReducer,
          projectRfaLogUiReducer
        ),
        submittalLog: createSceneReducer(
          projectSubmittalLogDataReducer,
          projectSubmittalLogUiReducer
        ),
        emailModal: createSceneReducer(
          projectEmailModalDataReducer,
          projectEmailModalUiReducer
        ),
        dailyReportLog: createSceneReducer(
          projectDailyReportLogDataReducer,
          projectDailyReportLogUiReducer
        ),
        fieldReports: createSceneReducer(
          projectFieldReportDataReducer,
          projectFieldReportUiReducer
        ),
        projectSites: createSceneReducer(
          projectSitesDataReducer,
          projectSitesUiReducer
        ),
        schedule: createSceneReducer(
          projectScheduleDataReducer,
          projectScheduleUiReducer
        ),
        documentation: createSceneReducer(projectDocumentationDataReducer),
        openItems: createSceneReducer(
          projectOpenItemsDataReducer,
          projectOpenItemsUiReducer
        ),
        closeout: createSceneReducer(projectCloseoutDataReducer),
        pdfViewer: createSceneReducer(usersNotificationModalUiReducer),
        settings: createSceneReducer(projectSettingsDataReducer),
        coiExps: createSceneReducer(projectCoiExpsDataReducer),
        projectTags: createSceneReducer(projectTagsDataReducer),
        photos: createSceneReducer(
          projectPhotosDataReducer,
          projectPhotosUiReducer
        ),
        dashboard: createSceneReducer(
          projectDashboardDataReducer,
          projectDashboardUiReducer
        ),
        securityCameras: createSceneReducer(
          projectSecurityCameraDataReducer,
          projectSecurityCameraUiReducer
        ),
        notificationSettings: createSceneReducer(notificationSettingsReducer),
        punchList: createSceneReducer(punchListDataReducer, punchListUiReducer),
      })
    ),
  })
);
