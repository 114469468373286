import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Project from 'domain/project';
import closePng from 'images/close.png';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { Drawer } from '@mui/material';
import Selector from 'components/shared/selector/Selector';
import DatePicker from 'components/shared/date-picker/DatePicker';
import MultipleSelector from 'components/shared/selector/MultipleSelector';

import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getProjectTags } from 'components/admin/projects/details/project-tags/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import {
  getProjectProposal,
  getCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { loadProjectTags } from 'components/admin/projects/details/project-tags/store/actions';
import {
  sameOrAfterDateValidation,
  sameOrBeforeDateValidation,
} from 'services/utils/date-util';

import {
  getPunchListFilters,
  getPunchListShowFilters,
} from './store/selectors';
import { setShowPunchListFilters } from './store/actions';
import {
  PUNCH_LIST_DEFAULT_FILTERS,
  itemTypeOptions,
  itemStatusOptions,
  itemPriorityOptions,
} from 'domain/punch-list';

import './PunchListFilters.css';
import ProjectPhotosFilterSitesAreas from '../project-photos/ProjectPhotosFilterSitesAreas';

const PunchListFilters = (props) => {
  const {
    dispatch,
    handleFilterChange,
    currentFilters,
    show,
    project,
    directory,
    projectTags,
  } = props;
  const [filters, setFilters] = useState(PUNCH_LIST_DEFAULT_FILTERS);

  useEffect(() => {
    if (show) setFilters({ ...currentFilters });
  }, [show]);

  useEffect(() => {
    loadDirectory();
    loadTags();
  }, []);

  const loadDirectory = () => {
    if (!directory || directory.project_id !== project.id) {
      dispatch(loadDirectoryRequest(project.id));
    }
  };
  const loadTags = () => {
    if (project.id && (!projectTags || projectTags.length === 0)) {
      dispatch(loadProjectTags(project.id));
    }
  };
  const onFilterChange = (newFilters = {}) => {
    setFilters((state) => ({ ...state, ...newFilters }));
  };
  const onClose = () => {
    dispatch(setShowPunchListFilters(false));
  };
  const onViewResults = () => {
    handleFilterChange(filters);
    onClose();
  };

  const userOptions = useMemo(
    () =>
      Project.recipientOptions({
        directory,
      }).map(({ id: value, label }) => ({ value, label })),
    [directory]
  );

  return (
    <Drawer open={show} anchor="right" onClose={onClose}>
      <div className="punch-list-filters">
        <div className="filters-header">
          <h4>Filters</h4>
          <span className="close-icon">
            <img src={closePng} alt="close" onClick={onClose} />
          </span>
        </div>
        <div className="filters">
          <div className="field">
            <h3>By Item Type</h3>
            <Selector
              className="select-dropdown"
              placeholder={'Item Type'}
              value={filters.type}
              onChange={(type) => onFilterChange({ type })}
              options={itemTypeOptions}
              clearable
            />
          </div>
          <div className="field">
            <h3>By Status</h3>
            <Selector
              className="select-dropdown"
              placeholder={'Status'}
              value={filters.status}
              onChange={(status) => onFilterChange({ status })}
              options={itemStatusOptions}
              clearable
            />
          </div>
          <div className="field">
            <h3>By Priority</h3>
            <Selector
              className="select-dropdown"
              placeholder={'Priority'}
              value={filters.priority}
              onChange={(priority) => onFilterChange({ priority })}
              options={itemPriorityOptions}
              clearable
            />
          </div>
          <div className="field">
            <h3>By Manager</h3>
            <Selector
              className="select-dropdown"
              placeholder="User"
              value={filters.manager}
              onChange={(manager) => onFilterChange({ manager })}
              options={userOptions}
              clearable
            />
          </div>
          <div className="field">
            <h3>By Assignee</h3>
            <Selector
              className="select-dropdown"
              placeholder="User"
              value={filters.assignee}
              onChange={(assignee) => onFilterChange({ assignee })}
              options={userOptions}
              clearable
            />
          </div>
          <div className="field">
            <h3>By Trade</h3>
            <MultipleSelector
              onChange={(trades) => onFilterChange({ trades })}
              value={filters.trades || []}
              className="trades-dropdown"
              placeholder="Trade"
              options={Project.csiCodesOptions(props)}
            />
          </div>
          <div className="field">
            <h3>By Location</h3>
            <ProjectPhotosFilterSitesAreas
              sites={filters.sites}
              areas={filters.areas}
              onChange={onFilterChange}
            />
          </div>
          <div className="field">
            <h3>By Tags</h3>
            <MultipleSelector
              onChange={(tags) => onFilterChange({ tags })}
              value={filters.tags || []}
              className="tags-dropdown"
              placeholder="Tag"
              options={projectTags}
            />
          </div>
          <div className="field">
            <h3>By Due Date</h3>
            <div className="line-field">
              <DatePicker
                className="due-date"
                placeholder="From"
                clearable
                value={filters.dueFrom}
                onChange={(dueFrom) => onFilterChange({ dueFrom })}
                isValidDate={(date) =>
                  sameOrBeforeDateValidation(date, filters.dueTo)
                }
              />
              <DatePicker
                className="due-date due-to-date"
                placeholder="To"
                clearable
                value={filters.dueTo}
                onChange={(dueTo) => onFilterChange({ dueTo })}
                isValidDate={(date) =>
                  sameOrAfterDateValidation(date, filters.dueFrom)
                }
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'View Items'}
            onClick={onViewResults}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default connect((state) => ({
  show: getPunchListShowFilters(state),
  currentFilters: getPunchListFilters(state),
  directory: getDirectory(state),
  project: getCurrentProject(state),
  projectProposal: getProjectProposal(state),
  projectTags: getProjectTags(state),
  currentUser: getCurrentUser(state),
  csiCodes: getCsiCodes(state),
}))(PunchListFilters);
