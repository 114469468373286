import {
  loadDirectoryRequest,
  searchDirectory,
} from 'components/admin/projects/details/project-directory/store/actions';
import {
  loadProjectProposalRequest,
  loadCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/actions';
import Project from 'domain/project';
import User from 'domain/user';
import { useEffect } from 'react';

const useDirectory = (props) => {
  const {
    dispatch,
    currentProject,
    directory,
    projectProposal,
    csiCodes,
    currentUser,
  } = props;

  useEffect(() => {
    loadDirectory();
    loadTradeCodes();
  }, []);

  const loadDirectory = () => {
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  const loadTradeCodes = () => {
    if (!projectProposal || projectProposal.project_id !== currentProject.id) {
      dispatch(loadProjectProposalRequest(currentProject.id)).then(
        (response) => {
          if (response.projectProposal || (csiCodes && csiCodes.length > 0))
            return;
          dispatch(loadCsiCodes());
        }
      );
    }
  };

  const loadContributors = () => {
    let users = {};
    return dispatch(searchDirectory(currentProject.id)).then((response) => {
      if (response.ok) {
        const contributors = response.directory.project_contributors
          .filter((pc) => pc.primary)
          .map((pc) =>
            pc.users
              .filter((usr) => !users[usr.id])
              .map((usr) => {
                users[usr.id] = true;
                const value = usr.id;
                const label = User.fullNameWithCompany({
                  ...usr,
                  accountable: pc.company,
                });
                return { value, label };
              })
          )
          .reduce((acc, val) => acc.concat(val), []);
        return { ok: true, options: contributors };
      }
    });
  };

  const recipientOptions = Project.recipientOptions({ directory });
  const tradeCodeOptions = Project.csiCodesOptions({
    project: currentProject,
    directory,
    currentUser,
    projectProposal,
    csiCodes,
  });

  return { loadContributors, tradeCodeOptions, recipientOptions };
};

export default useDirectory;
