import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';

import PunchListPermissions from 'permissions/punch-list-permissions';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getCurrentUser } from 'selectors/authentication';

import {
  deletePunchListItem,
  setPunchListReadOnly,
  setShowPunchListModal,
} from './store/actions';
import {
  getPunchListForm,
  getPunchListReadOnly,
  getPunchListShowModal,
} from './store/selectors';
import './PunchListModal.css';
// import PunchListNotes from './PunchListNotes';
import PunchListForm from './PunchListForm';

const PunchListModal = (props) => {
  const {
    dispatch,
    currentUser,
    currentProject,
    show,
    onClose,
    form,
    readOnly,
    history,
  } = props;

  if (!currentProject) return null;

  const [pdfUrl, setPdfUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);

  const handleClose = () => {
    dispatch(setShowPunchListModal(false));
    dispatch(setPunchListReadOnly(true));
    if (onClose) onClose();
  };
  const handleEdit = () => {
    dispatch(setPunchListReadOnly(!readOnly));
  };
  const handleSendEmail = () => dispatch(setShowEmailModal(true));
  const handleDelete = () => {
    dispatch(deletePunchListItem(currentProject.id, form.id));
    history.push(`/admin/projects/${currentProject.id}/punch_list`);
  };

  const handleViewPdf = () => {
    if (
      form &&
      form.pdf_document &&
      form.pdf_document.document &&
      form.pdf_document.document.url
    ) {
      setShowPdf(true);
      setPdfUrl(form.pdf_document.document.url);
    }
  };
  const handleCloseViewer = () => {
    setShowPdf(false);
    setPdfUrl('');
  };
  const renderPunchListActions = () => {
    const actions = [];
    if (readOnly && form.id) {
      if (PunchListPermissions.canEdit(currentUser, currentProject, form))
        actions.push({ icon: BUTTON_ICONS.EDIT, action: handleEdit });
      if (PunchListPermissions.canEmail(currentUser, currentProject, form))
        actions.push({ icon: BUTTON_ICONS.EMAIL, action: handleSendEmail });
      if (PunchListPermissions.canDelete(currentUser, currentProject, form))
        actions.push({ icon: BUTTON_ICONS.DELETE_BIN, action: handleDelete });

      actions.push({ icon: BUTTON_ICONS.PDF, action: handleViewPdf });
    }

    return actions.map((a, index) => (
      <Button
        key={`action_${index}`}
        type={BUTTON_TYPES.LINK}
        color={BUTTON_COLORS.GREEN}
        label={a.label}
        icon={a.icon}
        onClick={a.action}
      />
    ));
  };

  const renderSubtitle = () =>
    `${currentProject.name} - Item ${
      form && form.number ? form.formattedNumber : form.formattedStatus
    }`;

  return (
    <FormModal
      className={`punch-list-modal ${!form.id ? 'create-punch-list' : ''}`}
      show={show}
      onClose={handleClose}
      subTitle={renderSubtitle()}
      // TODO: Add the sidebar (Chat and stuff)
      /* sidebar={<PunchListNotes />} */
      actions={renderPunchListActions()}
    >
      <PunchListForm />
      <PdfViewer
        content={{
          source: pdfUrl,
          type: 'url',
        }}
        show={showPdf}
        onClose={handleCloseViewer}
      />
    </FormModal>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    show: getPunchListShowModal(state),
    form: getPunchListForm(state),
    readOnly: getPunchListReadOnly(state),
  };
})(PunchListModal);
