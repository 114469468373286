import {
  PUNCH_LIST_DEFAULT_FILTERS,
  PUNCH_LIST_DEFAULT_FORM,
} from 'domain/punch-list';
import {
  PUNCH_LIST_ADD_NOTE,
  PUNCH_LIST_RESET_FORM,
  PUNCH_LIST_SET_FILTERS,
  PUNCH_LIST_SET_FORM,
  PUNCH_LIST_SET_ITEMS,
  PUNCH_LIST_SET_NOTES,
  PUNCH_LIST_SET_PDF,
  PUNCH_LIST_ADD_ITEM,
  PUNCH_LIST_UPDATE_ITEM_SUCCESS,
  DELETE_ITEM_SUCCESFULLY,
} from './action-types';

const initialState = {
  items: [],
  total: 0,
  filters: PUNCH_LIST_DEFAULT_FILTERS,
  form: PUNCH_LIST_DEFAULT_FORM,
  pdf: null,
  notes: [],
};

export default function punchListDataReducer(state = initialState, action) {
  switch (action.type) {
    case PUNCH_LIST_SET_ITEMS: {
      let items = [...action.payload.items];
      let total = action.payload.total;

      if (action.payload.page > 1) {
        items = [state.items, ...items];
        total = state.total;
      }

      return { ...state, items, total };
    }
    case PUNCH_LIST_SET_FILTERS: {
      return { ...state, filters: action.payload.filters };
    }
    case PUNCH_LIST_RESET_FORM: {
      return { ...state, form: PUNCH_LIST_DEFAULT_FORM };
    }
    case PUNCH_LIST_SET_FORM: {
      return { ...state, form: { ...state.form, ...action.payload.form } };
    }
    case PUNCH_LIST_ADD_ITEM: {
      return {
        ...state,
        items: [action.payload.item, ...state.items],
        total: state.total + 1,
      };
    }
    case PUNCH_LIST_SET_PDF: {
      return { ...state, pdf: action.payload.pdf };
    }
    case PUNCH_LIST_SET_NOTES: {
      return { ...state, notes: action.payload.notes };
    }
    case PUNCH_LIST_ADD_NOTE: {
      return { ...state, notes: [...state.notes, action.payload.note] };
    }
    case PUNCH_LIST_UPDATE_ITEM_SUCCESS: {
      const modifiedItems = state.items.map((i) =>
        i.id === action.payload.id ? action.payload : i
      );

      return { ...state, items: modifiedItems };
    }
    case DELETE_ITEM_SUCCESFULLY:
      const modifiedItems = state.items.filter((i) => i.id !== action.payload);
      return { ...state, items: modifiedItems, form: PUNCH_LIST_DEFAULT_FORM };
    default:
      return state;
  }
}
