import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import AddProjectSiteModal from 'components/admin/projects/details/project-site-area/AddProjectSiteModal';
import Selector from 'components/shared/selector/Selector';
import {
  loadProjectSitesAreas,
  setShowAddProjectSiteModal,
} from './store/action';
import { getProjectSites } from 'components/admin/projects/details/project-site-area/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDarkMode } from 'selectors/theme';
import { Typography } from '@mui/material';

const SelectorProjectSites = (props) => {
  const {
    dispatch,
    onChange,
    value,
    projectSites,
    currentProject,
    readOnly,
    placeholder,
    valid,
    hideUnderline,
    className,
  } = props;

  const [sortByNumber, setSortByNumber] = useState(false);

  useEffect(() => {
    if (
      currentProject.id &&
      (!projectSites || projectSites.projectId !== currentProject.id)
    ) {
      dispatch(loadProjectSitesAreas(currentProject.id));
    }
  }, []);

  const sortAlphabetically = (a, b) => {
    const nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const sortNumerically = (a, b) => {
    const numberA = (a.number || '').toLowerCase(),
      numberB = (b.number || '').toLowerCase();
    if (numberA < numberB) return -1;
    if (numberA > numberB) return 1;
    return 0;
  };

  const options = useMemo(() => {
    const result = [];

    let count = 0;
    if (
      projectSites &&
      projectSites.sites &&
      currentProject &&
      projectSites.projectId === currentProject.id
    ) {
      projectSites.sites
        .sort(sortByNumber ? sortNumerically : sortAlphabetically)
        .forEach((s) => {
          let label = s.name;
          if (s.number && s.number !== '0') label += ` - #${s.number}`;
          result.push({ value: s.id, label });
          count++;
        });
    }

    result.unshift({
      value: 'add',
      label: <Typography color="secondary">Add Location</Typography>,
    });

    if (count > 1) {
      result.unshift({
        value: 'by_number',
        label: (
          <Typography color="secondary">
            List By Location {sortByNumber ? 'Name' : 'Number'}
          </Typography>
        ),
        closeOnSelect: false,
      });
    }

    if (result.length > 2)
      result.push({
        value: 'edit',
        label: <Typography color="secondary">Edit Locations</Typography>,
      });

    return result;
  }, [projectSites, sortByNumber]);

  const handleChange = (site) => {
    if (site.value === 'add') dispatch(setShowAddProjectSiteModal(true));
    else if (site.value === 'edit')
      dispatch(setShowAddProjectSiteModal(true, true));
    else if (site.value === 'by_number') setSortByNumber(!sortByNumber);
    else onChange(site);
  };

  return (
    <>
      <Selector
        value={value}
        placeholder={placeholder}
        valid={valid}
        onChange={handleChange}
        readOnly={readOnly}
        options={options}
        hideUnderline={hideUnderline}
        className={className}
      />
      {/* <FilterHeader
        className="project-site-area-selector"
        label={getLabel()}
        icon={darkMode ? vectorSelectedDarkSvg : vectorSelectedSvg}
        options={options}
        selected={selected}
        onFilter={handleChange}
        search={searching}
        upperCaseLabel
        clearable
      /> */}
      <AddProjectSiteModal />
    </>
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  projectSites: getProjectSites(state),
  currentProject: getCurrentProject(state),
}))(SelectorProjectSites);
