export const itemStatusOptions = [
  { value: null, label: 'Clear Option' },
  { value: 'draft', label: 'Draft' },
  { value: 'open', label: 'Open' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'on_review', label: 'On Review' },
  { value: 'completed', label: 'Complete' },
  { value: 'closed', label: 'Closed' },
];

export const itemPriorityOptions = [
  { value: null, label: 'Clear Option' },
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];

export const itemDueOptions = [
  { value: null, label: 'Clear Option' },
  { id: 'ascending', label: 'Ascending' },
  { id: 'descending', label: 'Descending' },
  { id: 'overdue', label: 'Overdue' },
];

export const itemTypeOptions = [
  { value: null, label: 'Clear Option' },
  { value: 'finish_work', label: 'Finish Work' },
  { value: 'electrical', label: 'Electrical' },
  { value: 'plumbing', label: 'Plumbing' },
  { value: 'carpentry', label: 'Carpentry' },
  { value: 'hvac', label: 'HVAC' },
  { value: 'exterior_work', label: 'Exterior Work' },
  { value: 'safety', label: 'Safety' },
  { value: 'clean_up', label: 'Clean-up' },
  { value: 'general_quality_control', label: 'General Quality Control' },
];

export const PUNCH_LIST_DEFAULT_FORM = {
  id: null,
  title: '',
  number: '',
  formattedNumber: '',
  description: '',
  documents: [],
  deleted_documents: [],
  photos: [],
  images: [],
  deleted_photos: [],
  due_date: null,
  priority: null,
  status: itemStatusOptions[1].value,
  formattedStatus: itemStatusOptions[1].label,
  tags: [],
  item_type: null,
  csi_code: null,
  project_site: null,
  projectArea: null,
  // users
  manager: null,
  approver: null,
  createdBy: null,
  assignees: [],
  errors: {},
};

export const PUNCH_LIST_DEFAULT_FILTERS = {
  page: 1,
  pageSize: 25,
  search: '',
  status: null,
  due_date: null,
  priority: null,
  item_type: null,
  assignee: null,
  manager: null,
  tags: [],
  sites: [],
  areas: [],
  csi_code: [],
  dueFrom: null,
  dueTo: null,
  titleOrder: null,
};
