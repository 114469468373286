/* eslint-disable default-case */
/* eslint-disable no-unreachable */ // <-- REMOVE

import axios from 'axios';
import numeral from 'numeral';
import BaseApi from './base-api';
import { getPunchListParams } from '../utils/punch-list';

class PunchListApi extends BaseApi {
  getItems(projectId, filters) {
    const url = `${
      this.baseUrl
    }/projects/${projectId}/punch_lists${getPunchListParams(filters)}`;
    console.log('filters', filters);
    return axios
      .get(url)
      .then((response) => ({
        ok: true,
        data: response.data,
        total: numeral(response.headers['total']).value(),
      }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getItemsPdf(projectId) {
    const url = `${this.baseUrl}/projects/${projectId}/punch_lists/print`;
    const config = { responseType: 'blob' };

    console.log('TODO: getItemsPdf replace backend call');

    return axios
      .get(url, config)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getItem(projectId, itemId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/punch_lists/${itemId}`)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  createItem(projectId, data) {
    const {
      documents,
      photos,
      deleted_documents,
      deleted_photos,
      ...restData
    } = data;
    const url = `${this.baseUrl}/projects/${projectId}/punch_lists`;
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const formData = new FormData();

    // Append form data
    Object.keys(restData).forEach((key) => {
      if (Array.isArray(restData[key])) {
        restData[key].forEach((item) => {
          if (typeof item === 'object') {
            formData.append(`punch_list[${key}][]`, item.value);
          }
          formData.append(`punch_list[${key}][]`, item);
        });
      } else {
        formData.append(`punch_list[${key}]`, restData[key]);
      }
    });

    // Handle file uploads for documents and photos
    let imagesIndex = 0;
    if (photos && photos.length > 0) {
      photos
        .filter((img) => img.file)
        .forEach((image) => {
          formData.append(
            `punch_list[attached_images_attributes][${imagesIndex}][image]`,
            image.file,
            image.file.name
          );

          imagesIndex++;
        });
    }

    if (deleted_photos && deleted_photos.length > 0) {
      deleted_photos.forEach((image) => {
        formData.append(
          `punch_list[attached_images_attributes][${imagesIndex}][id]`,
          image.id
        );
        formData.append(
          `punch_list[attached_images_attributes][${imagesIndex}][_destroy]`,
          true
        );
        imagesIndex++;
      });
    }

    let documentsIndex = 0;
    if (documents && documents.length > 0) {
      documents
        .filter((img) => img.file)
        .forEach((image) => {
          formData.append(
            `punch_list[attached_documents_attributes][${documentsIndex}][document]`,
            image.file,
            image.file.name
          );

          documentsIndex++;
        });
    }

    return axios
      .post(url, formData, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({
        ok: false,
        errors: error.response ? error.response.data.errors : [error.message],
      }));
  }

  createDraft(projectId, data) {
    data.status = 'draft';
    return this.createItem(projectId, data);
  }

  getNotes(itemId) {
    console.log('TODO: getNotes replace backend call');

    return axios
      .get(`${this.baseUrl}/punch_lists/${itemId}/notes`)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  markNotesRead(itemId) {
    console.log('TODO: markNotesRead replace backend call');

    return axios
      .put(`${this.baseUrl}/punch_lists/${itemId}/notes/mark_all_read`)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  createNote(itemId, content) {
    console.log('TODO: createNote replace backend call');

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const apiPayload = new FormData();
    apiPayload.append('note[content]', content.note);

    let index = 0;
    if (content.images && content.images.length > 0) {
      content.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `note[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    index = 0;
    if (content.documents && content.documents.length > 0) {
      content.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `note[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    return axios
      .post(`${this.baseUrl}/punch_lists/${itemId}/notes`, apiPayload, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  updateItem(projectId, itemId, data) {
    const url = `${this.baseUrl}/projects/${projectId}/punch_lists/${itemId}`;
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const {
      documents,
      photos,
      deleted_photos,
      deleted_documents,
      ...restData
    } = data;
    const formData = new FormData();

    Object.keys(restData).forEach((key) => {
      if (Array.isArray(restData[key])) {
        restData[key].forEach((item) => {
          if (typeof item === 'object') {
            formData.append(`punch_list[${key}][]`, item.value);
          }
          formData.append(`punch_list[${key}][]`, item);
        });
      } else {
        formData.append(`punch_list[${key}]`, restData[key]);
      }
    });

    // Handle file uploads for documents and photos

    let imagesIndex = 0;
    if (photos && photos.length > 0) {
      photos
        .filter((img) => img.file)
        .forEach((image) => {
          formData.append(
            `punch_list[attached_images_attributes][${imagesIndex}][image]`,
            image.file,
            image.file.name
          );

          imagesIndex++;
        });
    }

    if (deleted_photos && deleted_photos.length > 0) {
      deleted_photos.forEach((image) => {
        formData.append(
          `punch_list[attached_images_attributes][${imagesIndex}][id]`,
          image.id
        );
        formData.append(
          `punch_list[attached_images_attributes][${imagesIndex}][_destroy]`,
          true
        );
        imagesIndex++;
      });
    }

    let documentsIndex = 0;
    if (documents && documents.length > 0) {
      documents
        .filter((img) => img.file)
        .forEach((image) => {
          formData.append(
            `punch_list[attached_documents_attributes][${documentsIndex}][document]`,
            image.file,
            image.file.name
          );

          documentsIndex++;
        });
    }

    if (deleted_documents && deleted_documents.length > 0) {
      deleted_documents.forEach((image) => {
        formData.append(
          `punch_list[attached_documents_attributes][${documentsIndex}][id]`,
          image.id
        );
        formData.append(
          `punch_list[attached_documents_attributes][${documentsIndex}][_destroy]`,
          true
        );
        documentsIndex++;
      });
    }

    return axios
      .put(url, formData, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  deleteItem(projectId, itemId) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/punch_lists/${itemId}`)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  email(projectId, punchListId, recipients, subject, message) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const url = `${this.baseUrl}/projects/${projectId}/punch_lists/${punchListId}/email`;
    let data = new FormData();
    recipients.map((item) => data.append(`punch_lists[emails][]`, item));
    data.append(`punch_lists[subject]`, subject);
    data.append(`punch_lists[message]`, message);

    return axios
      .post(url, data, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
}

export const punchListApi = new PunchListApi();
