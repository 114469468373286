export const PUNCH_LIST_SET_ITEMS = 'PUNCH_LIST_SET_ITEMS';
export const PUNCH_LIST_SET_FILTERS = 'PUNCH_LIST_SET_FILTERS';
export const PUNCH_LIST_SET_FORM = 'PUNCH_LIST_SET_FORM';
export const PUNCH_LIST_RESET_FORM = 'PUNCH_LIST_RESET_FORM';
export const PUNCH_LIST_SET_READONLY = 'PUNCH_LIST_SET_READONLY';
export const PUNCH_LIST_SET_PDF = 'PUNCH_LIST_SET_PDF';
export const PUNCH_LIST_SET_NOTES = 'PUNCH_LIST_SET_NOTES';
export const PUNCH_LIST_ADD_ITEM = 'PUNCH_LIST_ADD_ITEM';
export const PUNCH_LIST_ADD_NOTE = 'PUNCH_LIST_ADD_NOTE';
export const PUNCH_LIST_SET_SHOW_FILTERS = 'PUNCH_LIST_SET_SHOW_FILTERS';
export const PUNCH_LIST_SET_SHOW_MODAL = 'PUNCH_LIST_SET_SHOW_MODAL';
export const SET_PUNCH_LIST_SHOW_LOCATIONS = 'SET_PUNCH_LIST_SHOW_LOCATIONS';
export const SET_SHOW_PUNCH_LIST_PDF_VIEWER = 'SET_SHOW_PUNCH_LIST_PDF_VIEWER';
export const PUNCH_LIST_UPDATE_ITEM_SUCCESS = 'PUNCH_LIST_UPDATE_ITEM_SUCCESS';
export const DELETE_ITEM_SUCCESFULLY = 'DELETE_ITEM_SUCCESFULLY';
