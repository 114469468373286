import {
  PUNCH_LIST_SET_READONLY,
  PUNCH_LIST_SET_SHOW_FILTERS,
  PUNCH_LIST_SET_SHOW_MODAL,
  SET_PUNCH_LIST_SHOW_LOCATIONS,
  SET_SHOW_PUNCH_LIST_PDF_VIEWER,
  DELETE_ITEM_SUCCESFULLY,
} from './action-types';

const initialState = {
  showPunchListFilters: false,
  showPunchListModal: true,
  readOnly: true,
  showPunchListPdfViewer: false,
};

export default function punchListUiReducer(state = initialState, action) {
  switch (action.type) {
    case PUNCH_LIST_SET_SHOW_FILTERS:
      return { ...state, showPunchListFilters: action.payload.show };
    case PUNCH_LIST_SET_SHOW_MODAL:
      return { ...state, showPunchListModal: action.payload.show };
    case PUNCH_LIST_SET_READONLY:
      return { ...state, readOnly: action.payload.readOnly };
    case SET_PUNCH_LIST_SHOW_LOCATIONS:
      return { ...state, showLocations: action.payload };
    case SET_SHOW_PUNCH_LIST_PDF_VIEWER: {
      return { ...state, showPunchListPdfViewer: true };
    }
    case DELETE_ITEM_SUCCESFULLY:
      return {
        ...state,
        showPunchListModal: false,
        readOnly: true,
      };
    default:
      return state;
  }
}
