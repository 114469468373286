// PunchListForm.hook.js
import {
  createPunchListDraft,
  createPunchListItem,
  resetPunchListForm,
  setPunchListForm,
  updatePunchListItem,
} from './store/actions';
import { mapFormToApiPayload } from 'services/utils/punch-list';

const usePunchListForm = ({ dispatch, form, currentProject, history }) => {
  const onFormChange = (fields) => dispatch(setPunchListForm(fields));

  const onCancel = () => {
    dispatch(resetPunchListForm());
    history.push(`/admin/projects/${currentProject.id}/punch_list`);
  };

  const onDraft = async () => {
    try {
      const errors = validateForm();

      if (Object.keys(errors).length > 0) {
        dispatch(setPunchListForm({ ...form, errors }));
        return;
      }
      const data = mapFormToApiPayload(form);
      await dispatch(createPunchListDraft(currentProject.id, data));
      history.push(`/admin/projects/${currentProject.id}/punch_list`);
      onCancel();
    } catch (error) {
      console.error('Error submitting Punch List item:', error);
    }
  };
  const validateForm = () => {
    let errors = {};
    if (!form.title) {
      errors = { ...errors, title: 'Title is mandatory' };
    }
    if (!form.item_type) {
      errors = { ...errors, item_type: 'Item Type is mandatory' };
    }
    if (!form.priority) {
      errors = { ...errors, priority: 'Priority Type is mandatory' };
    }
    if (!form.project_site) {
      errors = { ...errors, project_site: 'Location is mandatory' };
    }
    if (!form.manager) {
      errors = { ...errors, manager: 'Manager is mandatory' };
    }
    if (!form.approver) {
      errors = { ...errors, approver: 'Approver is mandatory' };
    }
    if (!(form.assignees && form.assignees.length > 0)) {
      errors = { ...errors, assignees: 'Assignees is mandatory' };
    }
    if (!form.description) {
      errors = { ...errors, description: 'Description is mandatory' };
    }
    if (!form.due_date) {
      errors = { ...errors, due_date: 'Due date is mandatory' };
    }
    if (!form.csi_code) {
      errors = { ...errors, csi_code: 'Cost code is mandatory' };
    }
    return errors;
  };
  const onSubmit = async () => {
    try {
      let editedForm = form;
      if (form.status === 'draft' || form.status.value === 'draft')
        editedForm = { ...form, status: 'open' };
      const data = mapFormToApiPayload(editedForm);

      // Check if editing or creating a new item

      const errors = validateForm();

      if (Object.keys(errors).length > 0) {
        dispatch(setPunchListForm({ ...form, errors }));
        return;
      }

      form.id
        ? await dispatch(updatePunchListItem(currentProject.id, form.id, data))
        : await dispatch(createPunchListItem(currentProject.id, data));

      history.push(`/admin/projects/${currentProject.id}/punch_list`);
      onCancel();
    } catch (error) {
      // Handle error
      console.error('Error submitting Punch List item:', error);
    }
  };

  return { onCancel, onDraft, onSubmit, onFormChange };
};

export default usePunchListForm;
