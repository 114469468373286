import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button, {
  BUTTON_TYPES,
  // BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import PunchListPermissions from 'permissions/punch-list-permissions';
import PunchListSearchFilter from './PunchListSearchFilter';
import PunchListModal from './PunchListModal';
import {
  loadPunchListItems,
  // loadPunchListPdf,
  resetPunchListForm,
  setPunchListForm,
  setPunchListReadOnly,
  setShowPunchListModal,
} from './store/actions';
import {
  getPunchListFilters,
  getPunchListItems,
  getPunchListShowModal,
} from './store/selectors';
import PunchListFilters from './PunchListFilters';
import PunchListTable from './PunchListTable';

import './ProjectPunchList.css';

const ProjectPunchList = (props) => {
  const {
    dispatch,
    currentUser,
    currentProject,
    renderHeaders,
    currentFilters,
    items,
    match,
    history,
    showModal,
  } = props;

  if (!currentProject) return null;

  const handleFilterChange = (filters = {}) => {
    const newFilters = { ...currentFilters, ...filters };
    console.log('currentFilters IN LOG', filters);
    console.log('newFilters', newFilters);
    dispatch(loadPunchListItems(currentProject.id, newFilters));
  };

  const handleAdd = () => {
    dispatch(resetPunchListForm());
    dispatch(setShowPunchListModal(true));
    dispatch(setPunchListReadOnly(false));
  };

  const handleEdit = (item) => {
    dispatch(setPunchListForm(item));
    dispatch(setPunchListReadOnly(true));
    history.push(`/admin/projects/${currentProject.id}/punch_list/${item.id}`);
  };

  const handleModalClose = () => {
    dispatch(setShowPunchListModal(false));
    dispatch(setPunchListReadOnly(true));
    dispatch(resetPunchListForm());
    history.push(`/admin/projects/${currentProject.id}/punch_list`);
  };

  useEffect(() => {
    handleFilterChange();
  }, []);

  useEffect(() => {
    const { itemId } = match.params;
    if (itemId) {
      let item = items.find((item) => item.id.toString() === itemId);

      if (item) {
        // If item is found, set form data and open modal
        dispatch(setPunchListForm(item));
        dispatch(setShowPunchListModal(true));
      }
    } else {
      // If not in edit mode, ensure modal is closed and form is reset
      dispatch(setShowPunchListModal(false));
      dispatch(resetPunchListForm());
    }
  }, [match.params, items, dispatch, currentProject.id, history]);

  const headerButtons = [
    <PunchListSearchFilter
      key="search-punch-list"
      handleFilterChange={handleFilterChange}
    />,
    PunchListPermissions.canSubmit(currentUser, currentProject) && (
      <Button
        key="add-item"
        type={BUTTON_TYPES.LINK}
        icon={BUTTON_ICONS.PLUS}
        label="Add Item"
        onClick={handleAdd}
      />
    ),
  ];

  return (
    <div className="punch-list-content-container">
      <div style={{ marginLeft: 20, paddingTop: 20 }}>
        {renderHeaders(headerButtons)}
      </div>
      {showModal && (
        <PunchListModal onClose={handleModalClose} history={history} />
      )}
      <PunchListFilters handleFilterChange={handleFilterChange} />
      <PunchListTable
        handleFilterChange={handleFilterChange}
        onEditItem={handleEdit}
      />
    </div>
  );
};

export default withRouter(
  connect((state) => {
    return {
      currentUser: getCurrentUser(state),
      currentProject: getCurrentProject(state),
      currentFilters: getPunchListFilters(state),
      items: getPunchListItems(state),
      showModal: getPunchListShowModal(state),
    };
  })(ProjectPunchList)
);
