import { createSelector } from 'reselect';

export const getPunchListFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.data.filters,
  (filters) => filters
);

export const getPunchListItems = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.data.items,
  (items) => items
);

export const getPunchListTotalItems = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.data.total,
  (total) => total
);

export const getPunchListShowFilters = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.punchList.ui.showPunchListFilters,
  (show) => show
);

export const getPunchListShowModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.punchList.ui.showPunchListModal,
  (show) => show
);

export const getPunchListForm = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.data.form,
  (form) => form
);

export const getPunchListPdf = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.data.pdf,
  (pdf) => pdf
);

export const getPunchListReadOnly = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.ui.readOnly,
  (readOnly) => readOnly
);

export const getPunchListNotes = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.data.notes,
  (notes) => notes
);

export const getPunchListShowLocations = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.ui.showLocations,
  (show) => show
);

export const getPunchListShowPdfViewer = createSelector(
  (state) => state.projects.scenes.details.scenes.punchList.ui.showPdfViewer,
  (show) => show
);
