import PunchList from 'services/utils/punch-list';
import User from 'domain/user';

class PunchListPermissions {
  canSubmit = (user, project) => User.isAdminOrProjectSubscriber(user, project);

  canEdit = (_user, _project, pl) => !PunchList.isClosed(pl);

  canEmail = (_user, _project, pl) => !PunchList.isClosed(pl);

  canDelete = (user, _project, pl) => {
    if (PunchList.isClosed(pl)) return false;

    if (User.isAdmin(user)) return true;

    if (PunchList.isOpen(pl)) return User.isSameCompany(user, pl.created_by);

    return false;
  };

  canManage = (user, project, pl) => {
    if (
      User.isAdminOrProjectSubscriber(user, project) ||
      User.isSame(user, pl.manager)
    )
      return true;

    if (
      (PunchList.isOpen(pl) || PunchList.isInProgress(pl)) &&
      pl.assignees.some((assignee) => User.isSame(user, assignee))
    )
      return true;

    return false;
  };
}

export default new PunchListPermissions();
